<template>
  <div class="Invention">
    <div class="contents">
      <div class="tabs">
        <el-tabs :tab-position="tabPosition"  @tab-click="handleClick" v-model="activeTab">
          <el-tab-pane label="系统展示" name="SystemDisplay"></el-tab-pane>
          <el-tab-pane label="专利" name="InventionPatent"></el-tab-pane>
          <el-tab-pane label="创新科技及成果" name="TechInnovaAchieve"></el-tab-pane>
        </el-tabs>
      </div>
       <div class="label_page">
        <div class="position">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home/systemDisplay' }">科技成果</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/home/systemDisplay' }">专利</el-breadcrumb-item>
          </el-breadcrumb>
           <!-- <el-input
              placeholder="请输入内容"
              suffix-icon="el-icon-search"
              v-model="input1" 
              size="small">
            </el-input> -->
        </div>
        <div class="display">
          <patentList></patentList>
          <!-- <div class="pa_display" v-for="data in tableData" :key="data.id">
            <router-link :to="'/home/sampleNews/'+data.id">{{data.patentName}}</router-link>
            <el-image :src="data.patentPicture"></el-image>
            <h2>{{data.patentName}}</h2>
            <p>该专利由武汉绿林茶籽油有限公司享有</p>
          </div> -->
        </div>
       
      </div>
    </div>
  </div>
</template>

<script>
import { request } from "@/network/request";
import patent from "@/components/patentList";
import patentList from '../../components/patentList.vue';
export default {
  components: { patentList },
  name:'InventionPatent',
  data () {
    return {
      tabPosition: 'left',
      activeTab:'InventionPatent',      
      tableData:[],    
    }
  },
   mounted:function(){
    
  },
  methods:{
    
     handleClick(tab,event){
            if(tab.label == '系统展示'){
                this.$router.push({path:'/home/systemDisplay'})
            }
            else if(tab.label == '专利'){
                 this.$router.push({path:'/home/inventionPatent'})
            }
            else{
                this.$router.push({path:'/home/techInnovaAchieve'})
            }
        },
  }
}
</script>

<style  scoped lang="less">
  .Invention{width: 1440px;margin: 0 auto;}
  .contents{display: inline-block;}
  .position{padding-bottom: 20px;border-bottom:3px solid #70A54D;margin-bottom: 20px;}
  .tabs{ padding-top: 40px;height: 300px;width: 200px;float: left;}
  /deep/ .el-tabs__item{height: 50px;font-size: 16px;font-weight: 400;color: #172F31;}
  /deep/ .el-tabs__item:hover{color: #BFD9DA;}
  /deep/ .el-tabs__item.is-active{color: #70A54D;}
  /deep/ .el-tabs__active-bar{background: #70A54D;height: 50px;}
  .label_page{height: 585px;padding-top: 40px;width: 1240px;float: left;}
  .display{ padding-top: 20px;width: 100%; display: inline-block;}
  .pa_display{ width: 100%;border-bottom: 1px solid #e6e6e6;overflow: hidden;padding-bottom: 18px;text-align: left;float: left;}
  // .pa_display{height:50px;width: 100%; box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);border-radius: 4px;float: left;margin-bottom:50px;margin-left: 50px;border:1px solid #70A54D;}
  /deep/ .el-image{padding-top: 20px;padding-bottom: 20px;height: 400px;width: 250px;}
  a{cursor: pointer;outline: none;color: #393939;text-decoration: none;}
</style>
