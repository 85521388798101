<template>
    <div  class="patentList">
      <div class="planTable" v-for="data in tableData" :key="data.id">
      <!-- <div v-for="data in tableData" :key="data.id" class="sample"> -->
        <!-- <ul class="left">
          <li class="Link"> -->
            <router-link :to="'/home/samplePatent/'+data.id">{{data.patentName}}</router-link>
          <!-- </li>
       </ul> -->
      </div>
      <div class="pagination">
        <el-pagination 
        background 
        :current-page="formLabelAlign.index"
        :page-size="formLabelAlign.pageSize"
        @current-change="handleCurrentChange" 
        :total="formLabelAlign.total"
        layout="total, prev, pager, next, jumper">
      </el-pagination>
      </div>
    </div>
</template>
<script>
import { request } from "@/network/request";
export default {
  props:['serviceNewsType'],
  data(){
    return {
      formLabelAlign:{
        index:1,
        pageSize:6,
        total:0,
      },
      NewsType:this.serviceNewsType,
      tableData:[],
    }
  },
   watch: {
    },
  mounted:function(){
    this.getAllPatent(this.formLabelAlign.index,this.formLabelAlign.pageSize)
  },
  methods:{
    getAllPatent(index,pageSize){
        let that = this;
      request({
        url: "/kjtpy/inventionPatent/getAllPatent",
        method: "post",
        data: {
          index:index,
          pageSize:pageSize
        }
      })
      .then((res) => {
        console.log(res)
        let data = res.data;
        if(data.code === 200){
          that.tableData = [];
          that.tableData = JSON.parse(JSON.stringify(res.data.content.data)); //深拷贝
          that.formLabelAlign.total = data.content.total
        }else{
          this.$message({
            message: data.content,
            type: 'warning',
            showClose: true
          });
        }
        
      })
      .catch((error) => {
        console.log(error);
      });
      },
       handleSizeChange(val) {
        this.formLabelAlign.pageSize = val
      this.formLabelAlign.index = 1
      this.getAllPatent(this.formLabelAlign.index,this.formLabelAlign.pageSize)
      },
      handleCurrentChange(val) {
        this.formLabelAlign.index = val
      this.getAllPatent(this.formLabelAlign.index,this.formLabelAlign.pageSize)
      }
  }
}
</script>

<style lang="scss" scoped>
  .left , .right{
    li{
      display: block;
      text-align: left;
      padding: 10px 0;
    }
  }
  .patentList{
    width: 100%;
  }
  .left{
    float: left;
    width: 60%;
  }
  .sample{
   padding-bottom: 18px;
   border-bottom: 1px solid #e6e6e6;
   overflow: hidden;
  }
  .right{
    float: left;
    width: 30%;
  }
  .pagination{
    padding-top: 10px;
    // float:left
  }
  a{cursor: pointer;outline: none;color: #393939;text-decoration: none;}
  .planTable{margin-bottom: 30px;padding: 10px; width: 90%;height: 60px;line-height: 60px; border-bottom:1px dashed rgb(233, 233, 233);border-left:1px solid rgb(233, 233, 233);text-align: left;}
  .planTable:hover{box-shadow:3px 3px 3px 3px #c8c8c8 ;}
  .postTitle{text-align: left;float: left;text-decoration: none;width: 80%;}
  a{color: #70a54d;font-weight: 1000;}
  a:hover {color:#398b03;text-decoration:underline;} /* ⿏标悬浮后的链接 */

</style>