<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/backIndex/bSystemDisplay' }">科技成果</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/backIndex/bInvenPatent' }">发明专利</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="table">
      <el-button type="primary" style="float:right" size="small" @click="openAddDialog()">新增</el-button>
      <el-table
    :data="tableData"
    stripe
    style="width: 100%">
    <el-table-column
      prop="patentName"
      label="专利名称" align="center"
      width="300">
    </el-table-column>
    <el-table-column
      prop="patentPicture"
      label="专利图片" align="center"
      width="300">
      <template slot-scope="scope">
        <el-button  type="success" size="mini" @click="showImg(scope.row.patentPicture)">查看</el-button>
      </template>
    </el-table-column>
    <el-table-column
      prop="patentIntroduce"
      label="专利介绍" align="center"
      width="480">
    </el-table-column>
    <el-table-column
      prop="option"
      label="操作" align="center"
      >
      <template slot-scope="scope">
        <el-button  type="primary" size="mini" @click="openEditDialog(scope.row)">编辑</el-button>
        <el-button  type="warning" size="mini" @click="deleteSyst(scope.row)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
    </div>
<!-- 分页 -->
    <div class="Pagination">
      <el-pagination 
        background 
        layout="total,sizes,prev, pager, next,jumper"  
        :current-page="formLabelAlign.index"
        :page-sizes="[10, 20, 30, 40,50,60]"
        :page-size="formLabelAlign.pageSize" 
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange" 
        :total="total"></el-pagination>
    </div>

    <!-- 新增对话框 -->
    <el-dialog title="新增" :visible.sync="addFormVisible " >
      <el-form :model="addForm" ref="addForm">
          <el-form-item label="专利名称" :label-width="formLabelWidth" prop="patentName">
              <el-input v-model="addForm.patentName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="专利介绍" :label-width="formLabelWidth" prop="patentIntroduce">
              <el-input v-model="addForm.patentIntroduce" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="专利图片" prop="patentPicture" style="margin-left:50px;" align="left">
              <el-upload
                  action=""
                  list-type="picture-card"
                  name="files"
                  :limit="1"
                  ref="uploadImg"
                  :headers = "headers"
                  :auto-upload = "false"
                  :on-success="
                      (res, file) =>handleAvatarSuccessAdd(res, file, 'addForm', 'patentPicture')"
                  :on-preview="
                      (file) =>handlePictureCardPreview(file)"
                  :on-remove="handleRemove"
                  :on-change="addImg"
                  :before-upload="beforeAvatarUpload"
                  >
                      <i class="el-icon-plus"></i>
                  </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="addForm.patentPicture" alt="" />
              </el-dialog>
          </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
          <el-button @click="addCarcer()">取 消</el-button>
          <el-button type="primary" @click="handleSubmit('addForm')">确 定</el-button>
      </div>
  </el-dialog>
<!-- 编辑 -->
  <el-dialog title="编辑" :visible.sync="editFormVisible">
    <el-form :model="editForm" ref="editForm">
    <el-form-item label="专利名称" :label-width="formLabelWidth" prop="patentName">
        <el-input v-model="editForm.patentName" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="专利介绍" :label-width="formLabelWidth" prop="patentIntroduce">
        <el-input v-model="editForm.patentIntroduce" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="专利图片" prop="patentPicture" style="margin-left:50px;" align="left">
        <el-upload
            action=""
            list-type="picture-card"
            name="files"
            :file-list="fileList"
            ref="uploadImg"
            :limit="1"
            :headers = "headers"
            :auto-upload = "false"
            :on-success="
                (res, file) =>handleAvatarSuccessEdit(res, file, 'editForm', 'patentPicture')"
            :on-preview="
                (file) =>handlePictureCardPreview(file)"
            :on-remove="handleRemoveEdit"
            :on-change="editImg"
            :before-upload="beforeAvatarUpload"
            >
                <!-- <i class="el-icon-plus"></i> -->
                <img width="100%" :src="editForm.patentPicture" alt="" style="width:100%;height:100%"/>
            </el-upload>
        <!-- <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="editForm.patentPicture" alt="" />
        </el-dialog> -->
    </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
          <el-button @click="editCarcer()">取 消</el-button>
          <el-button type="primary" @click="handleSubmit('editForm')">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog min-width="200px" title="系统图片" :visible.sync="dialogLookVisible" center>
      <div align="center">
          <img :src="pictureUrl" width="80%" style="margin: auto; height: 600px"/>
      </div>
      <p style="text-align: center; padding-top: 10px; color: red" v-show="lookPictureFlag">暂无图片</p>
    </el-dialog>

  </div>
</template>

<script>
import { request } from "@/network/request"
import { MessageBox } from 'element-ui';
export default {
  inject:['reload'],
  data () {
    return {
      dialogVisible: false,
      addFormVisible: false,
      editFormVisible: false,
      dialogLookVisible: false,
      lookPictureFlag: false,
      formLabelWidth:'150px',
      pictureUrl: "",
      fileList: [],
      addForm: {
        patentName: '',
        patentPicture: '',
        patentIntroduce: '',
      },
      editForm: {
        patentName: '',
        patentPicture: '',
        patentIntroduce: '',
      },
      tableData: [],
      formLabelAlign: {
        index:1,
        pageSize:10
      },
      total: 0,
      headers: {
         Authorization: sessionStorage.getItem('token')
      },
      editId: '',  //为了编辑的时候传参以记录每条的id
      files: {}
    }
  },
  mounted() {
    this.showAll();
  },
  methods: {
    addCarcer(){
      this.addFormVisible = false;
      request({
        url: '/kjtpy/img/deletePicture',
        method: 'post',   
        data: [this.addForm.patentPicture]
      }).then(res => {
        console.log("add-删除成功")
      })
    },
    editCarcer(){
      this.editFormVisible  = false;
      this.reload()
    },
    // 打开添加对话框
    openAddDialog() {
      this.addForm = {};
      this.addFormVisible = true;
      this.$nextTick(() => {
          this.$refs.addForm.clearValidate();
      });
    },
    //打开编辑对话框
    openEditDialog(val) {
      this.editId =val.id
      // let data = Object.assign({}, val);
      // this.editForm = data;
      this.editForm.patentName = val.patentName
      this.editForm.patentPicture = val.patentPicture
      this.editForm.patentIntroduce = val.patentIntroduce
      this.editFormVisible = true;
      this.$nextTick(() => {
          this.$refs.editForm.clearValidate();
      });
    },
    // 删除
    deleteSyst(row){
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示",{
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() =>{
        this.delete(row.id)
      }).catch(() => {
        this.$message({
          type: "info",
          message: "取消删除",
        })
      })
    },
    showAll(){
      request({
        url: '/kjtpy/inventionPatent/getAllPatent',
        method: 'post',
        data: {
          index: this.formLabelAlign.index,
          pageSize: this.formLabelAlign.pageSize
        }
      }).then(res => {
        if(res.data.code == 200){
          this.tableData = [],
          this.tableData = res.data.content.data,
          this.total = res.data.content.total
        }
      }).catch(err => {
        
      })
    },
    handleSubmit(form){
      this.$refs[form].validate(valid => {
        if(valid) {
          if(form == 'addForm') {
            this.confirmAdd();
          }
          if(form == 'editForm') {
            this.confirmEdit();
          }
        }else {
          this.$message({
            type: "warning",
            message: "您填写的信息有误，请重新检查",
          });
          return false;
        }
      })
    },
    // 添加
    confirmAdd(){
      let that = this;
      let data = Object.assign({},this.addForm);
      if(JSON.stringify(that.files) === '{}'){
        request({
          url: '/kjtpy/inventionPatent/addPatent',
          method: 'post',
          data: {
            patentName: that.addForm.patentName,
            patentPicture: '',
            patentIntroduce: that.addForm.patentIntroduce
          }
        }).then(res => {
          if(res.data.code == 200){
            this.addFormVisible = false,
            MessageBox("添加成功");
            this.$refs.uploadImg.clearFiles()
            this.showAll()
          }
        })
      }else{
        const formData = new FormData();
      formData.append("files",that.files)
      request({
        url: '/kjtpy/img/uploadPicture',
        method: 'post',
        headers:{"Content-Type": "multipart/form-data",},
        data: formData
      }).then(res => {
        if(res.data.code == 200){
          that.addForm.patentPicture=res.data.content
          request({
            url: '/kjtpy/inventionPatent/addPatent',
            method: 'post',
            data: {
              patentName: that.addForm.patentName,
              patentPicture: (that.addForm.patentPicture).toString(),
              patentIntroduce: that.addForm.patentIntroduce
            }
          }).then(res => {
            if(res.data.code == 200){
              this.addFormVisible = false,
              MessageBox("添加成功");
              this.$refs.uploadImg.clearFiles()
              this.showAll()
            }
          })
        }
      })
      }
      
      
      
    },
    // 编辑
    confirmEdit(){
      let that = this;
      let data = Object.assign({},this.editForm);
      console.log(data)
      if(JSON.stringify(that.files) === '{}'){
        request({
          url: '/kjtpy/inventionPatent/updatePatent',
          method: 'post',
          data: {
            id: this.editId,
            patentName: this.editForm.patentName,
            patentPicture: '',
            patentIntroduce: this.editForm.patentIntroduce
          },
         headers: {
           Authorization:sessionStorage.getItem('token')
         }
        }).then(res => {
          if(res.data.code == 200){
            this.editFormVisible = false;
            this.$refs.uploadImg.clearFiles()
            this.showAll();
          }
        }).catch(err => {
          MessageBox("更改错误,请重试.")
        })
      }else{
        const formData = new FormData();
        formData.append("files",that.files)
        let token = sessionStorage.getItem("token");
        // 先删除之前的图片，再上传图片，再更新这条数据
        request({
          url: '/kjtpy/img/deletePicture',
          method: 'post',
          data: [this.editForm.patentPicture]
        }).then(res => {
          if(res.data.code == 200){
            this.editForm.systemPicture = ""
            request({
              url: '/kjtpy/img/uploadPicture',
              method: 'post',
              headers: {
                "Content-Type": "multipart/form-data"
              },
              data : formData
            }).then(res => {
              if(res.data.code == 200){
                that.editForm.patentPicture = res.data.content
                request({
                  url: '/kjtpy/inventionPatent/updatePatent',
                  method: 'post',
                  data: {
                    id: this.editId,
                    patentName: this.editForm.patentName,
                    patentPicture: (this.editForm.patentPicture).toString(),
                    patentIntroduce: this.editForm.patentIntroduce
                  }
                }).then(res => {
                  if(res.data.code == 200){
                    this.editFormVisible = false;
                    this.$refs.uploadImg.clearFiles()
                    this.showAll();
                  }
                }).catch(err => {
                  MessageBox("更改错误,请重试!")
                })
              }
            })
        }
      })
      }

    },
    // 删除
    delete(val){
      request({
        url: '/kjtpy/inventionPatent/deletePatent',
        method: 'post',
        data: {
          id: val
        },
        Authorization: sessionStorage.getItem('token')
      }).then(res => {
        this.showAll();
      }).catch(err => {
        MessageBox("删除失败")
      })
    },
    showImg(val){
      this.dialogLookVisible = true;
      if(val != "" && val != undefined) {
        this.pictureUrl = val;
        this.lookPictureFlag = false;
      } else {
        this.pictureUrl = "";
        this.lookPictureFlag = true;
      }
    },
    // on-change
    addImg(file,fileList){
      this.files = file.raw
    },
    editImg(file,fileList){
      this.files = file.raw;
    },
    // 图片上传成功函数
    handleAvatarSuccessAdd(res, file, form, type) {
    if (res.code == "403") {
        this.$router.push("/");
    } else {
        this.addForm.patentPicture = res.content[0];
        console.log("赋值成功");
        console.log(res.content[0]);
    }
    },
    handleAvatarSuccessEdit(res, file, form, type) {
    if (res.code == "403") {
        this.$router.push("/");
    } else {
        this.editForm.systemPicture = res.content[0];
    }
    },
    // 图片预览函数
    handlePictureCardPreview(file) {
    this[form][type] = file.url;
    this.dialog.dialogVisible = true;
    },
    // 图片移除操作
    handleRemove(file, fileList) {
       
     this.uploadDisabled = false;
     this.editForm.patentPicture = ""
    },
    // 编辑-图片移除
    handleRemoveEdit(file, fileList){
      this.uploadDisabled = false;
      console.log(this.editForm.patentPicture)
      request({
        url: '/kjtpy/img/deletePicture',
        method: 'post',
        data: [this.editForm.patentPicture]
      }).then(res => {
        if(res.data.code == 200){
          this.editForm.patentPicture = ""
          // this.handleSubmit('editForm')
          console.log("edit-删除成功")
          let data = Object.assign({},this.editForm);
          request({
            url: '/kjtpy/inventionPatent/updatePatent',
            method: 'post',
            data:data
          }).then(res => {
            if(res.data.code == 200){
              this.showAll();
            }
          }).catch(err => {
            MessageBox("更改错误,请重试")
          })
        }
      }).catch(res => {

      })
    },
    // 图片上传前
    beforeAvatarUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
      this.$message.error('上传图片大小不能超过 512KB!');
    }
    return isLt2M;
    },

    handleSizeChange(val) {
      this.formLabelAlign.pageSize = val
      this.formLabelAlign.index = 1
      this.showAll()
    },
    handleCurrentChange(val) {
      this.formLabelAlign.index = val
      this.showAll()
    },
  }
}
</script>

<style  scoped>
.table{width: 1400px; margin: auto; margin-top: 50px; }
.Pagination{margin-top: 50px;}
</style>
