<template>
  <div class="ps">
    <div class="position">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home/workPlan' }" style="font"
          >工作进展与计划</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{
          companyName
        }}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/home/workPlanDetail' }">{{
          pstitle
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <button class="title" @click="down">下载文件</button>
    <iframe :src="pscontent" frameborder="0"></iframe>
  </div>
</template>

<script>
import { request } from "@/network/request";

export default {
  data() {
    return {
      msgId: "",
      psurl: "",
      pstitle: "",
      pscontent: "",
      companyName:""
    };
  },
  mounted: function () {
    this.geturl();
  },
  methods: {
    geturl() {
      this.msgId = JSON.parse(localStorage.getItem("id"));
      request({
        url: "/kjtpy/planAndSummary/getPsById",
        method: "post",
        data: {
          psId: this.msgId,
        },
      })
        .then((res) => {
          let data = res.data;
          if (data.code === 200) {
            this.psurl = data.content.psContent;
            var s = this.psurl.substring(this.psurl.lastIndexOf("."));
            this.pstitle = data.content.psTitle + s;
            this.companyName = data.content.stationName
            request({
              url: "/kjtpy/file/convertToPdf",
              method: "post",
              data: [this.psurl],
            })
              .then((res) => {
                let data = res.data;
                if (data.code === 200) {
                  this.pscontent = data.content;
                  // window.open(data.content);
                } else {
                  this.$message({
                    message: data.content,
                    type: "warning",
                    showClose: true,
                  });
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.$message({
              message: data.content,
              type: "warning",
              showClose: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    down() {
      window.open(this.psurl);
    },
  },
};
</script>

<style scoped>
.ps {
  margin-top: 50px;
  margin-left: 80px;
  width: 1500px;
  height: 1100px;
}
.position {
  margin-left: 20%;
  margin-bottom: 50px;
  float: left;
}
::v-deep .el-breadcrumb {
  font-size: 120%;
}
button {
  font-size: 15px;
  color: white;
  border-radius: 10px;
  border: none;
  float: right;
  width: 80px;
  height: 30px;
  background: linear-gradient(to right, #70a54d, #b1df92);
}
button:hover {
  -webkit-box-shadow: #70a54d 0px 0px 10px;
  -moz-box-shadow: #70a54d 0px 0px 10px;
  box-shadow: #70a54d 0px 0px 10px;
}
iframe {
  width: 80%;
  height: 90%;
  margin-left: 20%;
}
.position > el-breadcrumb {
  width: 200px;
  background-color: black;
}
/* .title {
  margin-left: 200px;
  padding: 20px;
  width: 8%;
  height: 20px;
  line-height: 20px;
  border-bottom: 1px dashed rgb(233, 233, 233);
  border-left: 1px solid rgb(233, 233, 233);
}
.title:hover {
  box-shadow: 3px 3px 3px 3px #c8c8c8;
}
a {
  color: #70a54d;
  font-weight: 1000;
  text-align: left;
  float: left;
  text-decoration: none;
}
a:hover {
  color: #398b03;
  text-decoration: underline;
} */
</style>