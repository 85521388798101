<template>
  <div class="System">
    <div class="contents">
      <div class="tabs">
        <el-tabs :tab-position="tabPosition"  @tab-click="handleClick" v-model="activeTab">
          <el-tab-pane label="系统展示" name="SystemDisplay"></el-tab-pane>
          <el-tab-pane label="专利" name="InventionPatent"></el-tab-pane>
          <el-tab-pane label="创新科技及成果" name="TechInnovaAchieve"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="label_page">
        <div class="position">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home/systemDisplay' }">科技成果</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/home/systemDisplay' }">系统展示</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
          <!-- <el-switch
            style="display: block"
            v-model="value2"
            active-color="#70A54D"
            inactive-color="#70A54D"
            active-text="网页端"
            inactive-text="移动端"
            @change="handleSwitch">
          </el-switch> -->
          <div>
          </div>
          <div class="switch_web" v-show="display">
            <div class="web_display" v-for="data in webData" :key="data.id">
              <el-image :src="data.systemPicture"></el-image>
              <h2>{{data.systemName}}</h2>
              <p>{{data.systemIntroduce}}</p>
              <a :href="'http://'+data.systemUrl">点击跳转链接</a>
            </div>
            <!-- <div class="Pagination">
            <el-pagination 
              background 
              :current-page="formLabelAlign.index"
              :page-size="formLabelAlign.pageSize"
              @current-change="handleCurrentChange" 
              :total="formLabelAlign.total"
              layout="total, prev, pager, next, jumper"></el-pagination>
        </div> -->
          </div>
          <!-- <div class="switch_phone" v-show="!display">
            <div class="phone_display" v-for="data in phoneData" :key="data.id">
              <el-image :src="data.systemPicture"></el-image>
              <h2>{{data.systemName}}</h2>
              <p>{{data.systemIntroduce}}</p>
              <a :href="'http://'+data.systemUrl">点击跳转链接</a>
            </div> -->
            <!-- <div class="Pagination">
            <el-pagination 
              background 
              :current-page="formLabelAlign.index"
              :page-size="formLabelAlign.pageSize"
              @current-change="handleCurrentChange" 
              :total="formLabelAlign.total"
              layout="total, prev, pager, next, jumper"></el-pagination>
        </div> -->
          <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { request } from "@/network/request";
export default {
  name:'SystemDisplay',
  data () {
    return {
      tabPosition: 'left',
      activeTab:'SystemDisplay',
      value2: true,
      display:true,
      // src: require('../../assets/img/llczyweb.png'),
      // webData:{
      //   id:'',
      //   systemName:'',
      //   systemIntroduce:'',
      //   systemUrl:'',
      //   systemPicture:'',
      //   systemFlag:''
      // },
      webData:[],
      phoneData:[],
      // phoneData:{
      //   id:'',
      //   systemName:'',
      //   systemIntroduce:'',
      //   systemUrl:'',
      //   systemPicture:'',
      //   systemFlag:''
      // },
    }
  },
  mounted:function(){
    this.getAllSystem()
  },
  methods:{
     handleClick(tab,event){
            if(tab.label == '系统展示'){
                this.$router.push({path:'/home/systemDisplay'})
            }
            else if(tab.label == '专利'){
                 this.$router.push({path:'/home/inventionPatent'})
            }
            else{
                this.$router.push({path:'/home/techInnovaAchieve'})
            }
      },
      handleSwitch(){
        if(this.value2){
          this.display = true
        }
        else{
          this.display = false
        }
      },
      getAllSystem(index,pageSize){
        let that = this;
      request({
        url: "/kjtpy/ShowSystem/getAllSystemNoPara",
        method: "post",
        data: {
          index:index,
          pageSize:pageSize
        }
      })
      .then((res) => {
        console.log(res)
        let data = res.data;
        if(data.code === 200){
          let k = 0
          let j = 0
         for(let i=0;i<res.data.content.length;i++)
         {
           if(res.data.content[i].systemFlag === 1)
           {
            //  this.webData[j] = res.data.content[i]
            //  this.webData[j].id = res.data.content[i].id
            //  this.webData[j].systemName = res.data.content[i].systemName
            //  this.webData[j].systemIntroduce = res.data.content[i].systemIntroduce
            //  this.webData[j].systemUrl = res.data.content[i].systemUrl
            //  this.webData[j].systemPicture = res.data.content[i].systemPicture
            //  this.webData[j].systemFlag = res.data.content[i].systemFlag
            //  j=j+1
            this.webData.push(res.data.content[i])
           }
           else{
            //  
            this.phoneData.push(res.data.content[i])
           }
         }
         console.log(this.phoneData)
         console.log("dsbvanhvfjhsagt")
         console.log(this.webData)
          // that.tableData = JSON.parse(JSON.stringify(res.data.content.data)); //深拷贝
        }else{
          this.$message({
            message: data.content,
            type: 'warning',
            showClose: true
          });
        }
        
      })
      .catch((error) => {
        console.log(error);
      });
      }
  }
}
</script>

<style  scoped lang='less'>
  .System{width: 1440px;margin: 0 auto; }
  .contents{display: inline-block;}
  .tabs{ padding-top: 40px;height: 300px;width: 200px;float: left;}
  /deep/ .el-tabs__item{height: 50px;font-size: 16px;font-weight: 400;color: #172F31;}
  /deep/ .el-tabs__item:hover{color: #BFD9DA;}
  /deep/ .el-tabs__item.is-active{color: #70A54D;}
  /deep/ .el-tabs__active-bar{background: #70A54D;height: 50px;}
  /deep/ .el-switch{text-align: left;}
  .position{padding-bottom: 20px;border-bottom:3px solid #70A54D;margin-bottom: 20px;}
  .label_page{padding-top: 40px;width: 1240px;float: left;}
  .switch_web{padding-top: 20px;display: inline-block;}
  .web_display{height:600px;width: 560px; box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);border-radius: 4px;float: left;margin-bottom:50px;margin-left: 50px;border:1px solid #70A54D;}
  .web_display p{text-align: left;text-indent:2em;padding-left: 10px;padding-right: 10px;}
  .switch_phone{padding-top: 20px;display: inline-block;}
  .phone_display{height:500px;width: 300px; box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);border-radius: 4px;float: left;margin-bottom:50px;margin-left: 50px;border:1px solid #70A54D;}
  .web_display /deep/ .el-image{padding-top: 20px;padding-bottom: 20px;height: 300px;width: 400px;}
  .phone_display /deep/ .el-image{padding-top: 20px;padding-bottom: 20px;height: 200px;width: 250px;}
</style>
