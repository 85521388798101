<template>
  <div id="app">
    <myheader></myheader>
    <router-view/>
    <myfooter></myfooter>
  </div>
</template>

<script>
// @ is an alias to /src
import myheader from '@/components/myheader'
import  myfooter from '@/components/myfooter'

export default {
  name: 'Home',
  components: {
    myheader,
    myfooter
  }
}
</script>

<style>
html,body{
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>
