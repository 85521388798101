<template>
  <div class="cotn_principal">
    <div class="cont_centrar">
      <div class="cont_login">
        <div class="cont_info_log_sign_up">
          <div class="col_md_login">
            <h2>登录</h2>
            <p></p>
            <button class="btn_login" @click="cambiar_login">登录</button>
          </div>
          <div class="col_md_sign_up">
            <h2>注册</h2>
            <p></p>
            <button class="btn_sign_up" @click="cambiar_sign_up">注册</button>
          </div>
        </div>
        <div class="cont_back_info">
          <div class="cont_img_back_grey">
            <img
              src="https://images.unsplash.com/42/U7Fc1sy5SCUDIu4tlJY3_NY_by_PhilippHenzler_philmotion.de.jpg?ixlib=rb-0.3.5&q=50&fm=jpg&crop=entropy&s=7686972873678f32efaf2cd79671673d"
              alt=""
            />
          </div>
        </div>
        <div class="cont_forms">
          <div class="cont_img_back_">
            <img
              src="https://images.unsplash.com/42/U7Fc1sy5SCUDIu4tlJY3_NY_by_PhilippHenzler_philmotion.de.jpg?ixlib=rb-0.3.5&q=50&fm=jpg&crop=entropy&s=7686972873678f32efaf2cd79671673d"
              alt=""
            />
          </div>
          <div class="cont_form_login" :model="login">
            <a href="#" @click="ocultar_login_sign_up"
              ><i class="material-icons">&#xE5C4;</i></a
            >
            <h2>登录</h2>
            <input
              type="text"
              placeholder="用户名/电话/邮箱"
              v-model="login.id"
            />
            <input
              type="password"
              placeholder="密码"
              v-model="login.password"
            />
            <button
              class="btn_login"
              @click="btn_login(login.id, login.password)"
            >
              登录
            </button>
          </div>
          <!-- <div class="cont_form_sign_up" :model="sign_up">
                    <a href="#" @click="ocultar_login_sign_up"><i class="material-icons">&#xE5C4;</i></a>
                    <h2>注册</h2>
                    <input type="text" placeholder="用户名（必填）"  v-model="sign_up.username"/>
                    <input type="text" placeholder="电话（必填）" v-model="sign_up.telephone"/>
                    <input type="text" placeholder="邮箱（必填）" v-model="sign_up.email"/>
                    <input type="password" placeholder="密码（必填）" v-model="sign_up.password"/>
                    <button class="btn_sign_up" @click="btn_sign_up">注册</button>
                </div> -->
          <el-form
            :model="sign_up"
            :rules="rules"
            ref="sign_up"
            class="cont_form_sign_up"
          >
            <a href="#" @click="ocultar_login_sign_up"
              ><i class="material-icons">&#xE5C4;</i></a
            >
            <h2>注册</h2>
            <el-form-item prop="username">
              <el-input
                v-model="sign_up.username"
                placeholder="用户名（必填）"
              ></el-input>
            </el-form-item>
            <el-form-item prop="telephone">
              <el-input
                v-model="sign_up.telephone"
                placeholder="电话（必填）"
              ></el-input>
            </el-form-item>
            <el-form-item prop="email">
              <el-input
                v-model="sign_up.email"
                placeholder="邮箱（必填）"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="sign_up.password"
                placeholder="密码（必填）"
              ></el-input>
            </el-form-item>
            <button class="btn_sign_up" @click="btn_sign_up">注册</button>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://wow.techbrood.com/libs/jquery/jquery-1.11.1.min.js"></script>

<script>
import { request } from "@/network/request";
import md5 from "js-md5";
export default {
  data() {
    return {
      login: {
        id: "",
        password: "",
      },
      sign_up: {
        username: "",
        telephone: "",
        email: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "请填写用户名", trigger: "blur" },
        ],
        telephone: [{ required: true, message: "请填写电话", trigger: "blur" }],
        email: [{ required: true, message: "请填写邮箱", trigger: "blur" }],
        password: [{ required: true, message: "请填写密码", trigger: "blur" }],
      },
    };
  },

  methods: {
    cambiar_login() {
      document.querySelector(".cont_forms").className =
        "cont_forms cont_forms_active_login";
      document.querySelector(".cont_form_login").style.display = "block";
      document.querySelector(".cont_form_sign_up").style.opacity = "0";

      setTimeout(function () {
        document.querySelector(".cont_form_login").style.opacity = "1";
      }, 400);

      setTimeout(function () {
        document.querySelector(".cont_form_sign_up").style.display = "none";
      }, 200);
    },
    cambiar_sign_up(at) {
      document.querySelector(".cont_forms").className =
        "cont_forms cont_forms_active_sign_up";
      document.querySelector(".cont_form_sign_up").style.display = "block";
      document.querySelector(".cont_form_login").style.opacity = "0";

      setTimeout(function () {
        document.querySelector(".cont_form_sign_up").style.opacity = "1";
      }, 100);

      setTimeout(function () {
        document.querySelector(".cont_form_login").style.display = "none";
      }, 400);
    },
    ocultar_login_sign_up() {
      document.querySelector(".cont_forms").className = "cont_forms";
      document.querySelector(".cont_form_sign_up").style.opacity = "0";
      document.querySelector(".cont_form_login").style.opacity = "0";

      setTimeout(function () {
        document.querySelector(".cont_form_sign_up").style.display = "none";
        document.querySelector(".cont_form_login").style.display = "none";
      }, 500);
    },
    btn_login() {
      request({
        url: "/kjtpy/admin/login",
        method: "post",
        data: {
          adminName: this.login.id,
          adminPassword: md5(this.login.password),
          adminTelephone: this.login.id,
          adminEmail: this.login.id,
        },
      })
        .then((res) => {
          console.log(res);
          let data = res.data;
          if (data.code == 200) {
            console.log(data.content.admin[0]);
            sessionStorage.setItem("token", res.data.content.token),
              sessionStorage.setItem("id", res.data.content.admin.adminName),
              sessionStorage.setItem(
                "password",
                res.data.content.admin.adminPassword
              ),
              sessionStorage.setItem("adminId", res.data.content.admin.adminId);
            //   sessionStorage.setItem("adminName", this.login.username);
            //   sessionStorage.setItem("adminPassword", this.login.password);
            //   sessionStorage.setItem("adminTelephone", this.login.telephone);
            //   sessionStorage.setItem("adminEmail", this.login.email);
            this.$router
              .push({ path: "/backIndex/bSystemDisplay" })
              .catch(() => {});
          } else {
            this.$message({
              message: res.data.content,
              type: "warning",
              showClose: true,
            });
          }
        })
        .catch((error) => {});
    },
    btn_sign_up() {
      if (
        this.sign_up.username != "" &&
        this.sign_up.password != "" &&
        this.sign_up.telephone != "" &&
        this.sign_up.email != ""
      ) {
        request({
          url: "/kjtpy/admin/register",
          method: "post",
          data: {
            adminName: this.sign_up.username,
            adminPassword: md5(this.sign_up.password),
            adminTelephone: this.sign_up.telephone,
            adminEmail: this.sign_up.email,
          },
        })
          .then((res) => {
            if (res.data.code == 200) {
              window.location.reload();
              this.$message({
                message: "注册成功！",
                type: "success",
                showClose: true,
              });
            } else {
              this.$message({
                message: data.content,
                type: "warning",
                showClose: true,
              });
            }
          })
          .catch((error) => {});
      } else {
        this.$message({
          message: "注册失败!",
          type: "warning",
          showClose: true,
        });
      }
    },
  },
};
</script>

<style  scoped >
* {
  margin: 0px auto;
  padding: 0px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
}
.cotn_principal {
  position: absolute;
  width: 100%;
  height: 100%;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#cfd8dc+0,607d8b+100,b0bec5+100 */

  background: #cfd8dc;
  /* Old browsers */

  background: -moz-linear-gradient(
    -45deg,
    #cfd8dc 0%,
    #607d8b 100%,
    #b0bec5 100%
  );
  /* FF3.6-15 */

  background: -webkit-linear-gradient(
    -45deg,
    #cfd8dc 0%,
    #607d8b 100%,
    #b0bec5 100%
  );
  /* Chrome10-25,Safari5.1-6 */

  background: linear-gradient(135deg, #cfd8dc 0%, #607d8b 100%, #b0bec5 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#cfd8dc', endColorstr='#b0bec5', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
}
.cont_centrar {
  position: relative;
  float: left;
  width: 100%;
}
.cont_login {
  position: relative;
  width: 640px;
  left: 50%;
  margin-left: -320px;
}
.cont_back_info {
  position: relative;
  float: left;
  width: 640px;
  height: 280px;
  overflow: hidden;
  background-color: #fff;
  margin-top: 100px;
  box-shadow: 1px 10px 30px -10px rgba(0, 0, 0, 0.5);
}
.cont_forms {
  position: absolute;
  overflow: hidden;
  top: 100px;
  left: 0px;
  width: 320px;
  height: 280px;
  background-color: #eee;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.cont_forms_active_login {
  box-shadow: 1px 10px 30px -10px rgba(0, 0, 0, 0.5);
  height: 420px;
  top: 20px;
  left: 0px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.cont_forms_active_sign_up {
  box-shadow: 1px 10px 30px -10px rgba(0, 0, 0, 0.5);
  height: 420px;
  top: 20px;
  left: 320px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.cont_img_back_grey {
  position: absolute;
  width: 950px;
  top: -80px;
  left: -116px;
}
.cont_img_back_grey > img {
  width: 100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.2;
  animation-name: animar_fondo;
  animation-duration: 20s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.cont_img_back_ {
  position: absolute;
  width: 950px;
  top: -80px;
  left: -116px;
}
.cont_img_back_ > img {
  width: 100%;
  opacity: 0.3;
  animation-name: animar_fondo;
  animation-duration: 20s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
.cont_forms_active_login > .cont_img_back_ {
  top: 0px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.cont_forms_active_sign_up > .cont_img_back_ {
  top: 0px;
  left: -435px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.cont_info_log_sign_up {
  position: absolute;
  width: 640px;
  height: 280px;
  top: 100px;
  z-index: 1;
}
.col_md_login {
  position: relative;
  float: left;
  width: 50%;
}
.col_md_login > h2 {
  font-weight: 400;
  margin-top: 70px;
}
.col_md_login > p {
  font-weight: 400;
  margin-top: 15px;
  width: 80%;
}
.btn_login {
  background-color: #26c6da;
  border: none;
  padding: 10px;
  width: 200px;
  border-radius: 3px;
  box-shadow: 1px 5px 20px -5px rgba(0, 0, 0, 0.4);
  color: #fff;
  margin-top: 10px;
  cursor: pointer;
}
.col_md_sign_up {
  position: relative;
  float: left;
  width: 50%;
}
.col_md_sign_up > h2 {
  font-weight: 400;
  margin-top: 70px;
}
.col_md_sign_up > p {
  font-weight: 400;
  margin-top: 15px;
  width: 80%;
}
.btn_sign_up {
  background-color: #ef5350;
  border: none;
  padding: 10px;
  width: 200px;
  border-radius: 3px;
  box-shadow: 1px 5px 20px -5px rgba(0, 0, 0, 0.4);
  color: #fff;
  margin-top: 10px;
  cursor: pointer;
}
.cont_forms_active_sign_up {
  z-index: 2;
}
@-webkit-keyframes animar_fondo {
  from {
    -webkit-transform: scale(1) translate(0px);
    -moz-transform: scale(1) translate(0px);
    -ms-transform: scale(1) translate(0px);
    -o-transform: scale(1) translate(0px);
    transform: scale(1) translate(0px);
  }
  to {
    -webkit-transform: scale(1.5) translate(50px);
    -moz-transform: scale(1.5) translate(50px);
    -ms-transform: scale(1.5) translate(50px);
    -o-transform: scale(1.5) translate(50px);
    transform: scale(1.5) translate(50px);
  }
}
@-o-keyframes identifier {
  from {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
}
@-moz-keyframes identifier {
  from {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
}
@keyframes identifier {
  from {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
}
.cont_form_login {
  position: absolute;
  opacity: 0;
  display: none;
  width: 320px;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.cont_forms_active_login {
  z-index: 2;
}

.cont_form_sign_up {
    margin: 20px;
  position: absolute;
  width: 280px;
  float: left;
  opacity: 0;
  display: none;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.cont_form_sign_up > h2 {
  margin-top: 50px;
  margin-bottom: 10px;
  font-weight: 400;
}
.cont_form_login > input {
  padding: 15px 5px;
  margin-left: 10px;
  margin-top: 20px;
  width: 260px;
  border: none;
  text-align: left;
}
.cont_form_login > h2 {
  margin-top: 50px;
  font-weight: 400;
}
.cont_form_login > a,
.cont_form_sign_up > a {
  color: #757575;
  position: relative;
  float: left;
  margin: 10px;
  margin-left: 30px;
}
</style>
