import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

import Home from '../views/home/Home.vue'

import index from '@/views/home/index'
import workSite from '@/views/home/workSite'
import systemDisplay from '@/views/home/systemDisplay'
import inventionPatent from '@/views/home/inventionPatent'
import techInnovaAchieve from '@/views/home/techInnovaAchieve'
import scienTechServ from '@/views/home/scienTechServ'
import serviceRecord from '@/views/home/serviceRecord'
import innovateEnterprise from '@/views/home/innovateEnterprise'
import scienTechTrain from '@/views/home/scienTechTrain'
import workPlan from '@/views/home/workPlan'
import contactUs from '@/views/home/contactUs'
import userLogin from '@/views/home/userLogin'
import sampleNews from '@/views/home/sampleNews'
import samplePatent from '@/views/home/samplePatent'
import workPlanDetail from '@/views/home/workPlanDetail'

import backIndex from '@/views/backstage/backIndex'
import bSystemDisplay from '@/views/backstage/bSystemDisplay'
import bTechAchieve from '@/views/backstage/bTechAchieve'
import bInvenPatent from '@/views/backstage/bInvenPatent'
import bWorkSite from '@/views/backstage/bWorkSite'
import bScienServ from '@/views/backstage/bScienServ'
import bScienServEdit from '@/views/backstage/bScienServEdit'
import bWorkPlan from '@/views/backstage/bWorkPlan'
import Newsupdate from '@/views/backstage/Newsupdate'


const routes = [
  {
    path: '/',
    redirect: '/home/index'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    children: [
      {
        path: 'index',
        name: 'index',
        component: index,
        meta: {
          title: '中心首页'
        }
      },
      {
        path: 'workSite',
        name: 'workSite',
        component: workSite,
        meta: {
          title: '工作站点'
        }
      },
      {
        path: 'systemDisplay',
        name: 'systemDisplay',
        component: systemDisplay,
        meta: {
          title: '系统展示'
        }
      },
      {
        path: 'inventionPatent',
        name: 'inventionPatent',
        component: inventionPatent,
        meta: {
          title: '发明专利'
        }
      },
      {
        path: 'techInnovaAchieve',
        name: 'techInnovaAchieve',
        component: techInnovaAchieve,
        meta: {
          title: '科技创新及成果'
        },
        
      },
      {
        path: 'scienTechServ',
        name: 'scienTechServ',
        component: scienTechServ,
        meta: {
          title: '科技服务'
        }
      },
      {
        path: 'serviceRecord',
        name: 'serviceRecord',
        component: serviceRecord,
        meta: {
          title: '服务纪实'
        }
      },
      {
        path: 'innovateEnterprise',
        name: 'innovateEnterprise',
        component: innovateEnterprise,
        meta: {
          title: '创新创业'
        }
      },
      {
        path: 'scienTechTrain',
        name: 'scienTechTrain',
        component: scienTechTrain,
        meta: {
          title: '科技培训'
        }
      },
      {
        path:'sampleNews/:id',
        name:'sampleNews',
        component:sampleNews,
        meta: {
          title: '新闻详情'
        }
      },
      {
        path:'samplePatent/:id',
        name:'samplePatent',
        component:samplePatent,
        meta: {
          title: '专利详情'
        }
      },
      {
        path: 'workPlan',
        name: 'workPlan',
        component: workPlan,
        meta: {
          title: '工作计划'
        }
      },
      
      {
        path: 'contactUs',
        name: 'contactUs',
        component: contactUs,
        meta: {
          title: '联系我们'
        }
      },
      {
        path: 'userLogin',
        name: 'userLogin',
        component: userLogin,
        meta: {
          title: '登录'
        }
      },
      {
        path: 'workPlanDetail',
        name: 'workPlanDetail',
        component: workPlanDetail,
        meta: {
          title: '工作计划详情'
        }
      }
    ]
  },
  // 后台
  {
    path: '/backIndex',
    redirect: '/backIndex/bSystemDisplay'
  },
  {
    path:'/backIndex',
    name: 'backIndex',
    component: backIndex,
    meta: {
      title: '后台管理',
      requireAuth: true   //设置该路由及其子路由是否需要登录权限
    },
    children: [
      {
        path: 'bSystemDisplay',
        name: 'bSystemDisplay',
        component: bSystemDisplay,
        meta: {
          title: '系统展示后台'
        }
      },
      {
        path: 'bTechAchieve',
        name: 'bTechAchieve',
        component: bTechAchieve,
        meta: {
          title: '创新技术及成果'
        }
      },
      {
        path: 'bInvenPatent',
        name: 'bInvenPatent',
        component: bInvenPatent,
        meta: {
          title: '发明专利'
        }
      },
      {
        path: 'bWorkSite',
        name: 'bWorkSite',
        component: bWorkSite,
        meta: {
          title: '工作站点'
        }
      },
      {
        path: 'bScienServ',
        name: 'bScienServ',
        component: bScienServ,
        meta: {
          title: '科技服务'
        }
      },
      {
        path:'Newsupdate/:id',
        name:'Newsupdate',
        component:Newsupdate,
        meta: {
          title: '修改新闻'
        }
      },
      {
        path: 'bScienServEdit',
        name: 'bScienServEdit',
        component: bScienServEdit,
        meta: {
          title: '科技服务编辑'
        }
      },
      {
        path: 'bWorkPlan',
        name: 'bWorkPlan',
        component: bWorkPlan,
        meta: {
          title: ' 工作进展与计划'
        }
      },
    ]
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
