<template>
  <div class="workPlan">
    <div class="contents">
      <div class="tabs">
        <el-tabs :tab-position="tabPosition"   v-model="activeTab">
          <el-tab-pane v-for="(item,index) in companyItemList" :key="index" >
            <span slot="label" @click="getAll(item.id)">{{item.companyName}}</span>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="label_page">
        <div class="position">
        </div>
          <div class="planDisplay">
          <div class="planTable" v-for="data in tableData" :key="data.id">
              <a class="postTitle" href="#" @click="getId(data.psId)">{{data.psTitle}}</a>
              <div class="postTime">{{data.psTimeStr}}</div>
          </div>
        </div>
        <el-pagination 
              background 
              :current-page="formLabelAlign.index"
              :page-size="formLabelAlign.pageSize"
              :page-sizes="[5,10,15,20]"
              @current-change="handleCurrentChange" 
              @size-change="handleSizeChange"
              :total="total"
              layout="total,sizes,prev, pager, next,jumper"></el-pagination>
        </div>
      </div>
    </div>
</template>

<script>
import { request } from "@/network/request";
export default {
  name:'WorkPlan',
  data () {
    return {
      companyItemList: [],
      formLabelAlign:{
        index:1,
        pageSize:5,
        stationId:1,
        
      },
      total:0,
      tableData:[],
      tabPosition: 'left',
      activeTab:'WorkPlan',
    }
  },
  mounted:function(){
    this.show();
    this.getAllPsType(this.formLabelAlign.index,this.formLabelAlign.pageSize,this.formLabelAlign.stationId);
  },
  methods:{
    show(){
        request({
          url: "/kjtpy/WorkStation/getAllWorkStation",
          method: "post",
          data:{
          }
        }).then(res => {
          if(res.data.code == 200){
            this.companyItemList=res.data.content;
          }else if(res.data.code == 500){
            MessageBox.alert("未找到信息");
          }

        }).catch(err => {
          
        })
      },
      getAll(stationId){
        this.tableData='';
      request({
        url: "/kjtpy/planAndSummary/getALLPSByType",
        method: "post",
        data: {
          index:this.formLabelAlign.index,
          pageSize:this.formLabelAlign.pageSize,
          stationId:stationId
        }
      })
      .then((res) => {
         let that=this;
        let data = res.data;
        if(data.code === 200){
          that.formLabelAlign.stationId=stationId
          that.tableData = [];
          that.tableData = res.data.content.list;
          that.total = data.content.size
        }else{
          this.$message({
            message: data.content,
            type: 'warning',
            showClose: true
          });
        }
        
      })
      .catch((error) => {
        console.log(error);
      });
      
      },
      getAllPsType(index,pageSize,stationId){
      request({
        url: "/kjtpy/planAndSummary/getALLPSByType",
        method: "post",
        data: {
          index:this.formLabelAlign.index,
          pageSize:this.formLabelAlign.pageSize,
          stationId:this.formLabelAlign.stationId
        }
      })
      .then((res) => {
        let that=this;
        let data = res.data;
        if(data.code === 200){
          that.tableData = [];
          that.tableData = res.data.content.list;
          that.total = data.content.size
        }else{
          this.$message({
            message: data.content,
            type: 'warning',
            showClose: true
          });
        }
        
      })
      .catch((error) => {
        console.log(error);
      });
      
      },
      getId(val){
      localStorage.setItem('id',JSON.stringify(val)) 
      this.$router.push({path:'/home/workPlanDetail'})
      },
      handleSizeChange(val) {
        this.formLabelAlign.pageSize = val
        this.formLabelAlign.index = 1
        this.getAllPsType(this.formLabelAlign.index,this.formLabelAlign.pageSize,this.formLabelAlign.stationId)
      },
      handleCurrentChange(val) {
        console.log(val)
        this.formLabelAlign.index = val
        this.getAllPsType(this.formLabelAlign.index,this.formLabelAlign.pageSize,this.formLabelAlign.stationId)
      }
  },
}
</script>

<style  scoped lang="less">
  .workPlan{width: 1440px; margin: 0 auto;height: 700px;}
  .contents{display: inline-block;}
  .tabs{ padding-top: 40px;height: 300px;width: 400px;float: left;}
  .tabs span{text-align: center;display: inline-block;width: 330px;}
  /deep/ .el-tabs__item{margin-bottom: 5px;margin-right: 10px; height: 50px;line-height: 50px; font-size: 18px;font-weight: 400;color: #ffffff;border-radius: 5px;background: rgb(112,165,77);}
  /deep/ .el-tabs__item:hover{color: rgb(14,86,4);}
  /deep/ .el-tabs__item.is-active{color: rgb(14,86,4);}
  /deep/ .el-tabs__active-bar {background: rgb(0,128,0);}
  /deep/ .el-tabs__nav-wrap::after{background-color: #fff;}
  .position{padding-bottom: 20px;border-bottom:3px solid #70A54D;margin-bottom: 20px;}
  .label_page{padding-top: 40px;width: 1040px;float: left;}
  .planTable{margin-bottom: 30px;padding: 10px; width: 100%;height: 60px;line-height: 60px; border-bottom:1px dashed rgb(233, 233, 233);border-left:1px solid rgb(233, 233, 233);}
  .planTable:hover{box-shadow:3px 3px 3px 3px #c8c8c8 ;}
  .postTitle{text-align: left;float: left;text-decoration: none;}
  a{color: #70a54d;font-weight: 1000;}
  a:hover {color:#398b03;text-decoration:underline;} /* ⿏标悬浮后的链接 */
  .postTime{text-align: right;color:rgb(175, 175, 175) ;}
</style>
