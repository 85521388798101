import axios from 'axios'
import config from '@/config'

const baseURL = process.env.NODE_ENV === 'development' ? config.baseURL.dev : config.baseURL.prod;
axios.defaults.withCredentials = true;
//请求响应函数
export function request(config) {
    const http = axios.create({
        baseURL : baseURL,
        timeout : 30000,
        headers: {
          //headers 请求头
          //给请求头添加Authorization属性
          //Authorization属性的值就是token
          Authorization: sessionStorage.getItem('token')
      }
    })
  //  请求拦截器
   http.interceptors.request.use(
    config => {
      if (sessionStorage.getItem('token')) {
        // window.location.href = "https://sso.znglzx.com/cas/login?service=http://authority.znglzx.com/authorize-mid/cas/caslogin";
        config.headers.token=sessionStorage.getItem('token')
      }
      return config;
  },
  err => {
    return Promise.reject(err);
  });

//   //响应拦截器
//   http.interceptors.response.use(
//     response => {
//       let initialUrl = 'http://authority.znglzx.com/authorize-mid/cas/caslogin';
//       let url = response.request.responseURL;
//       let index = response.request.responseURL.indexOf('?');
//       if (url.substring(0,index) == initialUrl) {
//           window.location.href = "https://sso.znglzx.com/cas/login?service=http://authority.znglzx.com/authorize-mid/cas/caslogin";
//       }
//       return response;
//     },
//     err => {
//       return Promise.reject(err.response.status);
//     }
//   )
  return http(config);
}
// export function request1(config){
//     const http = axios.create({
//         baseURL : 'http://localhost:8080',
//         timeout : 30000,
//     })
//     return http(config);
//   }