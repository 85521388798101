<template>
<div class="news">
  <div class="bu_style">
    <el-button type="success" @click="goBack" >返回上一级</el-button>
  </div>
  <h2>{{list.serviceNewsTitle}}</h2>
  <div class="ql-editor ql-snow">
    <div class="ql-editor" v-html="change(list.serviceNewsContent)"></div>
  </div>
</div>
</template>
<script>
import { request } from "@/network/request";
export default {
  data(){
    return {
      list:[]
    }
  },
  methods:{
    change(content) {
      let t = content.replace("<iframe", `<video style="width:1000px;margin-left:100px;outline:none;" controls="" autoplay=""`).replace("</iframe>", '</video>');
      return t
    },
    goBack(){
      this.$router.back();
    },
    getNewsById(val){
      let that = this;
      request({
        url: "/kjtpy/serviceNews/getNewsById",
        method: "post",
        data: {
          serviceNewsId:val
        }
      })
      .then((res) => {
        console.log(res)
        let data = res.data;
        if(data.code === 200){
          that.list = [];
          that.list = res.data.content; //深拷贝
        }else{
          this.$message({
            message: data.content,
            type: 'warning',
            showClose: true
          });
        }
        
      })
      .catch((error) => {
        console.log(error);
      });
    }
  },
  mounted:function(){
    this.getNewsById(this.$route.params.id)
  }
}
</script>
<style  scoped lang="less">
.ql-editor {
    min-height:400px; height:auto!important; height:400px;
    }
 .news{
    padding: 0 15px;
    width: 1200px;
    margin: 0 auto;
  }
.bu_style{padding: 20px 0 20px 0;text-align: left;}
</style>