<template>
  <el-card>
    <div class="worksite">工作计划与进展</div>
    <el-button type="primary" class="add" size="small" @click="add()"
      >添加</el-button
    >
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="psTitle" label="计划/总结名称" width="400">
      </el-table-column>
      <el-table-column
        label="预览"
        min-width="100"
        align="center"
        prop="psContent"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="success"
            @click="open(scope.row.psContent)"
            >预览</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        label="上传日期"
        min-width="100"
        align="center"
        prop="psTimeStr"
      >
      </el-table-column>
      <el-table-column
        label="公司名称"
        min-width="100"
        align="center"
        prop="stationName"
      >
      </el-table-column>
      <el-table-column
        label="操作"
        min-width="100"
        align="center"
        prop="operate"
      >
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="edit(scope.row)"
            >编辑</el-button
          >
          <el-button @click="deleteSys(scope.row)" size="mini" type="warning"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="Pagination">
      <el-pagination
        background
        layout="total,sizes,prev, pager, next,jumper"
        :current-page="formLabelAlign.index"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="formLabelAlign.pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :total="total"
      ></el-pagination>
    </div>

    <!-- 添加弹框 -->
    <el-dialog
      title="添加"
      :visible.sync="addFormVisible"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-form :model="addForm" ref="addForm">
        <el-form-item
          label="计划/总结名称"
          :label-width="formLabelWidth"
          prop="name"
        >
          <el-input v-model="addForm.systemName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="公司名称"
          prop="type"
          :label-width="formLabelWidth"
        >
          <el-select v-model="addForm.stationId" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="系统文件"
          prop="watch"
          style="margin-left: 50px"
          align="left"
        >
          <el-upload
            action=""
            :file-list="this.addForm.systemFile"
            :headers="headers"
            list-type="picture"
            :on-exceed="handleExceed"
            :limit="1"
            :auto-upload="false"
            :on-remove="handleRemove"
            :on-change="addFile"
          >
            <el-button slot="trigger" type="primary">添加系统文件</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="back()">取 消</el-button>
        <el-button type="primary" @click="submit()">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 预览 -->
    <el-dialog :visible.sync="fileVisible" class="watch">
      <iframe :src="pscontent" frameborder="0"></iframe>
    </el-dialog>

    <!-- 编辑弹框 -->
    <el-dialog
      title="编辑"
      :visible.sync="editFormVisible"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-form :model="editForm" ref="editForm">
        <el-form-item
          label="计划/总结名称"
          :label-width="formLabelWidth"
          prop="name"
        >
          <el-input v-model="editForm.systemName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="公司名称"
          prop="type"
          :label-width="formLabelWidth"
        >
          <el-select v-model="editForm.stationId" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.companyName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left: 50px" align="left" label="系统文件">
          <el-upload
            action=""
            :file-list="this.editForm.systemFile"
            :headers="headers"
            list-type="picture"
            :on-exceed="handleExceed"
            :limit="1"
            :auto-upload="false"
            :on-remove="handleRemoveEdit"
            :on-change="addFileEdit"
          >
            <el-button slot="trigger" type="primary">上传系统文件</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="back()">取 消</el-button>
        <el-button type="primary" @click="editsubmit()">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import { request } from "@/network/request";
export default {
  data() {
    return {
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
      pscontent: "",
      fileVisible: false,
      stationName: "",
      dialogVisible: false,
      addFormVisible: false,
      tableData: [],
      options: "",
      addForm: {
        systemName: "",
        systemFile: [],
        systemTime: "",
        stationName: "",
        stationId: "",
        id: "",
      },
      editFormVisible: false,
      editForm: {
        systemName: "",
        systemFile: [],
        systemTime: "",
        stationName: "",
        stationId: "",
        id: "",
      },
      Form: {
        systemName: "",
        systemFile: [],
        systemTime: "",
        stationName: "",
        stationId: "",
        id: "",
      },
      formLabelAlign: {
        index: 1,
        pageSize: 10,
      },
      total: 0,
      formLabelWidth: "120px",
    };
  },
  mounted() {
    this.showAll();
  },
  methods: {
    addFile(file, fileList) {
      this.addForm.systemFile = fileList;
    },
    addFileEdit(file, fileList) {
      this.editForm.systemFile = fileList;
    },
    add() {
      this.addForm = {};
      this.addFormVisible = true;
      this.$nextTick(() => {
        this.$refs.addForm.clearValidate();
      });
    },
    // 显示所有数据
    showAll() {
      request({
        url: "/kjtpy/planAndSummary/getALLPSByType",
        method: "post",
        data: {
          index: this.formLabelAlign.index,
          pageSize: this.formLabelAlign.pageSize,
          stationId: this.stationId,
        },
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.tableData = [];
            this.tableData = res.data.content.list;
            this.total = res.data.content.size;
          }
        })
        .catch((err) => {});

      request({
        url: "/kjtpy/WorkStation/getAllWorkStation",
        method: "post",
        data: {},
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.options = res.data.content;
          } else if (res.data.code == 500) {
            MessageBox.alert("未找到信息");
          }
        })
        .catch((err) => {});
    },
    // 添加数据
    submit() {
      console.log(this.addForm.systemFile);
      if (this.addForm.systemFile == undefined) {
        request({
          url: "/kjtpy/planAndSummary/insertPs",
          method: "post",
          headers: { Authorization: sessionStorage.getItem("token") },
          data: {
            psTitle: this.addForm.systemName,
            psContent: "",
            stationId: this.addForm.stationId,
            psTime: "",
          },
        }).then((res) => {
          if (res.data.code == 200) {
            this.showAll();
            this.addFormVisible = false;
            this.$nextTick(() => {
              this.$refs.addForm.clearValidate();
              this.$message({
                type: "success",
                message: "添加成功!",
              });
            });
          }
        });
      }
      if (this.addForm.systemFile[0] != null) {
        var formDatas = new FormData();
        formDatas.append("files", this.addForm.systemFile[0].raw);
        request({
          url: "/kjtpy/file/uploadFile",
          method: "post",
          data: formDatas,
        })
          .then((res) => {
            this.addForm.systemFile[0].url = res.data.content[0];
            console.log("成功上传！");

            request({
              url: "/kjtpy/planAndSummary/insertPs",
              method: "post",
              headers: { Authorization: sessionStorage.getItem("token") },
              data: {
                psTitle: this.addForm.systemName,
                psContent: this.addForm.systemFile[0].url,
                stationId: this.addForm.stationId,
                psTime: "",
              },
            }).then((res) => {
              if (res.data.code == 200) {
                this.showAll();
                this.addFormVisible = false;
                this.$nextTick(() => {
                  this.$refs.addForm.clearValidate();
                  this.$message({
                    type: "success",
                    message: "添加成功!",
                  });
                });
              }
            });
          })
          .catch((err) => {});
      }
    },

    back() {
      window.location.reload();
    },
    handleRemove() {
      this.addForm.systemFile = [];
    },
    handleRemoveEdit() {
      this.Form.systemFile = this.editForm.systemFile;
      this.editForm.systemFile = [];
    },

    // 预览
    open(val) {
      if (val != "" && val != null) {
        request({
          url: "/kjtpy/file/convertToPdf",
          method: "post",
          data: [val],
        })
          .then((res) => {
            this.fileVisible = true;
            this.pscontent = res.data.content;
          })
          .catch((err) => {});
      } else {
        alert("没有可预览文件！");
      }
    },
    // 编辑数据
    edit(val) {
      console.log(val);
      this.editFormVisible = true;
      this.editForm.systemName = val.psTitle;
      this.editForm.stationName = val.stationName;
      this.editForm.stationId = val.stationId;
      this.editForm.id = val.psId;
      if (val.psContent) {
        let temp = {
          name: "1.jpg",
          url: val.psContent,
        };
        this.editForm.systemFile.push(temp);
      }
      let data = Object.assign({}, this.editForm);
      this.Form = data;
    },
    editsubmit() {
      if (this.editForm.systemFile[0] == this.Form.systemFile[0]) {
        request({
          url: "/kjtpy/planAndSummary/updatePS",
          method: "post",
          data: {
            psTitle: this.editForm.systemName,
            psContent: "",
            stationId: this.editForm.stationId,
            psTime: "",
            psId: this.editForm.id,
          },
        })
          .then((res) => {
            console.log("成功更新");
            this.showAll();
            window.location.reload();
          })
          .catch((err) => {});
      }
    if(this.editForm.systemFile[0]!=undefined){
      const formData = new FormData();
      formData.append("files", this.editForm.systemFile[0].raw);
      request({
        url: "/kjtpy/file/uploadFile",
        method: "post",
        data: formData,
      }).then((res) => {
        if (res.data.code == 200) {
          this.editForm.systemFile[0].url = res.data.content[0];
          console.log(this.editForm.systemFile[0].url);
          request({
            url: "/kjtpy/planAndSummary/updatePS",
            method: "post",
            headers: { Authorization: sessionStorage.getItem("token") },
            data: {
              psTitle: this.editForm.systemName,
              psContent: this.editForm.systemFile[0].url,
              stationId: this.editForm.stationId,
              psTime: "",
              psId: this.editForm.id,
            },
          }).then((res) => {
            if (res.data.code == 200) {
              if (this.Form.systemFile[0] != undefined&&this.editForm.systemFile[0] == this.Form.systemFile[0]) {
                request({
                  url: "/kjtpy/file/deleteFilesPdf",
                  method: "post",
                  data: [this.Form.systemFile[0].url],
                })
                  .then((res) => {
                    console.log("成功删除！");
                  })
                  .catch((err) => {});
              }
              this.showAll();
              this.editFormVisible = false;
              window.location.reload();
              this.$nextTick(() => {
                this.$refs.editForm.clearValidate();
                this.$message({
                  type: "success",
                  message: "编辑成功!",
                });
              });
            }
          });
        }
      });
      
      }
      if(this.editForm.systemFile[0]==undefined){
        request({
            url: "/kjtpy/planAndSummary/updatePS",
            method: "post",
            headers: { Authorization: sessionStorage.getItem("token") },
            data: {
              psTitle: this.editForm.systemName,
              psContent: "",
              stationId: this.editForm.stationId,
              psTime: "",
              psId: this.editForm.id,
            },
          }).then((res) => {
            if (res.data.code == 200) {
              if (this.Form.systemFile[0] != undefined) {
                request({
                  url: "/kjtpy/file/deleteFilesPdf",
                  method: "post",
                  data: [this.Form.systemFile[0].url],
                })
                  .then((res) => {
                    console.log("成功删除！");
                  })
                  .catch((err) => {});
              }
              this.showAll();
              this.editFormVisible = false;
              window.location.reload();
              this.$nextTick(() => {
                this.$refs.editForm.clearValidate();
                this.$message({
                  type: "success",
                  message: "编辑成功!",
                });
              });
            }
          });
      }
    },

    // 删除一行
    deleteSys(val) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request({
            url: "/kjtpy/planAndSummary/deletePS",
            method: "post",
            data: {
              psId: val.psId,
            },
          })
            .then((res) => {
              if (res.data.code == 200) {
                this.showAll();
              }
            })
            .catch(() => {});
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件`);
    },
    //换页
    handleSizeChange(val) {
      this.formLabelAlign.pageSize = val;
      this.formLabelAlign.index = 1;
      this.showAll();
    },
    handleCurrentChange(val) {
      this.formLabelAlign.index = val;
      this.showAll();
    },
  },
};
</script>

<style  scoped lang="less">
.worksite {
  float: left;
}
.add {
  float: right;
  margin-bottom: 20px;
}
.Pagination {
  margin-top: 50px;
}
iframe {
  width: 100%;
  height: 650px;
}
/deep/ .el-select {
  display: block;
}
</style>
