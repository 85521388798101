<template>
<div id="main">
  <div class="footer">
    <p class="p1">Copyright©2020 All Right Reserved. </p>
    <p> 版权所有© 湖北省制造企业智能管理工程技术研究中心   |   <a href="https://beian.miit.gov.cn/">鄂ICP备16008630号-15</a></p>
  </div>
</div>
  
</template>

<script>
export default {
  data () {
    return {
    }
  }
}
</script>

<style  scoped>
.main{margin: 0; padding: 0; display: flex;}
.footer{width: 100%; height: 100px; background-color:#70a54d; bottom: 0; flex: 0;}
.p1{padding-top: 20px;}
p{color: white; }
a{text-decoration: none; color: white;}
</style>
