<template>
  <div class="main">
    <div class="carous">
      <el-carousel  :interval="4000">
      <el-carousel-item v-for="item in carouseImg" :key="item.url" >
        <img 
          :src="item.url" alt ></img>
      </el-carousel-item>
      </el-carousel>
    </div>
    

    <div class="numbRecord">
        <hr class="hr1"/>
        <div class="inner">
          <p class="innerChi">纪实视频</p>
          <p class="innerEng">Documentary Video</p>
        </div>
        <hr class="hr2"/>
      </div>
      <div class="video">
        <video-player class="video-player vjs-custom-skin"
          preload="none"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
        ></video-player>
      </div>

    <div class="content">
      <div class="sciencSer">
      <hr class="hr1"/>
      <div class="inner">
        <p class="innerChi">科技服务</p>
        <p class="innerEng">Science and Technology Services</p>
      </div>
      <hr class="hr2"/>
      </div>

      <div class="sciencSerCont">
        <div class="serLeft">
          <el-carousel  :interval="4000"  arrow="never">
            <el-carousel-item v-for="item in serImg" :key="item.url">
              <el-image 
                :src="item.url"></el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="serRight" >
          <!-- <p @click="newsDetail(item.serviceNewsId)">{{item.serviceNewsTitle}}</p> -->
          <!-- <div class="swiper-container">
            <div class="swiper-slide">slider1</div>
            <div class="swiper-slide">slider2</div>
            <div class="swiper-slide">slider3</div>
          </div> -->
          <div class="serRight1" v-for="(item,index) of newsList.slice(0,2)" :key="index">
            <router-link :to="'/home/sampleNews/'+item.serviceNewsId"><p>{{item.serviceNewsTitle}}</p></router-link><hr/>
          </div>
          <div class="serRight2" v-for="(item,index) of newsInnovList.slice(0,2)" :key="index">
            <router-link :to="'/home/sampleNews/'+item.serviceNewsId"><p>{{item.serviceNewsTitle}}</p></router-link><hr/>
          </div>
          <div class="serRight3" v-for="(item,index) of newsTrainList.slice(0,2)" :key="index">
            <router-link :to="'/home/sampleNews/'+item.serviceNewsId"><p>{{item.serviceNewsTitle}}</p></router-link><hr/>
          </div>
        </div>
      </div>

      

      <div class="copCompMap">
        <hr class="hr1"/>
        <div class="inner">
          <p class="innerChi">合作公司分布地图</p>
          <p class="innerEng">Distribution Map of Cooperative Companies</p>
        </div>
        <hr class="hr2"/>
        <div class="map">
          <baidu-map :center="center"
          :zoom="zoom"
          class="bm-view"
          @ready="handler"
          :scroll-wheel-zoom="true"
          ak="YhbSXlFYifR1w6NGSX2ONl8GTOtZgcl4">
              <bm-marker :position="{lng: model.lng, lat: model.lat}" :dragging="true">
                <bm-label content="绿林茶籽油" :labelStyle="labelStyle" :offset="{width: -35, height: 30}"/>
              </bm-marker>
              <bm-marker :position="{lng: 114.224992, lat: 31.063923}" :dragging="true">
                <bm-label content="木兰春然" :labelStyle="labelStyle" :offset="{width: 25, height: 10}"/>
              </bm-marker>
              <bm-marker :position="{lng: 114.44445, lat: 30.812856}" :dragging="true">
                <bm-label content="高家庄生态农业" :labelStyle="labelStyle" :offset="{width: 25, height: 10}"/>
              </bm-marker>
          </baidu-map>
        </div>
      </div>
      </div>
    
  </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import { request } from "@/network/request"

export default {
  data () {
    return {
      newsList: [],
      newsInnovList: [],
      newsTrainList: [],
      center: {lng:0,lat: 0},
      zoom: 3,
      model: {
        lng: 114.232854,
        lat: 31.068928,
        address: ''
      },
      carouseImg:[
        {url: require("../../assets/img/carous1.jpg")},
        {url: require("../../assets/img/carous2.jpg")},
        {url: require("../../assets/img/carous6.png")},
      ],
      serImg:[
        {url: require("../../assets/img/carous3.jpg")},
        {url: require("../../assets/img/carous4.jpg")},
        {url: require("../../assets/img/carous5.jpg")},
      ],
      playerOptions : {
        playbackRates : [ 0.5, 1.0, 1.5, 2.0 ], //可选择的播放速度
        autoplay : false, //如果true,浏览器准备好时开始回放。
        muted : false, // 默认情况下将会消除任何音频。
        loop : false, // 视频一结束就重新开始。
        preload : 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language : 'zh-CN',
        aspectRatio : '16:8', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid : true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources : [ {
            type : "video/mp4",
            src : require("../../assets/video.mp4") //url地址
        } ],
        poster : require("../../assets/img/videoImg.png"), //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage : '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar : {
            timeDivider : true,//当前时间和持续时间的分隔符
            durationDisplay : true,//显示持续时间
            remainingTimeDisplay : false,//是否显示剩余时间功能
            fullscreenToggle : true  //全屏按钮
        }
    },
    labelStyle: {
      color: 'red', fontSize : '20px'
    }
    }
  },
  mounted(){
    this.showNewsRec();
    this.showNewsInnov();
    this.showNewsTrain();
  },

  methods: {
    handler({BMap, map}) {
      this.center.lng = 114.39345458960705;
      this.center.lat = 30.4954068141862;
      this.zoom = 9;
    },
    showNewsRec(){
      request({
        url: '/kjtpy/serviceNews/getAllNews',
        method: 'post',
        data:{
          index: 1,
          pageSize: 5,
          serviceNewsType: 1
        }
      }).then(res => {
        if(res.data.code == 200){
          this.newsList=res.data.content.list;
        }else if(res.data.code == 500){

        }
      }).catch(err => {

      })
    },
    showNewsInnov(){
      request({
        url: '/kjtpy/serviceNews/getAllNews',
        method: 'post',
        data:{
          index: 1,
          pageSize: 5,
          serviceNewsType: 2
        }
      }).then(res => {
        if(res.data.code == 200){
          this.newsInnovList=res.data.content.list;
        }else if(res.data.code == 500){

        }
      }).catch(err => {

      })
    },
    showNewsTrain(){
      request({
        url: '/kjtpy/serviceNews/getAllNews',
        method: 'post',
        data:{
          index: 1,
          pageSize: 5,
          serviceNewsType: 3
        }
      }).then(res => {
        console.log(res)
        if(res.data.code == 200){
          this.newsTrainList=res.data.content.list;
        }else if(res.data.code == 500){

        }
      }).catch(err => {

      })
    },
    // newsDetail(val){
    // this.$router.push({path:'/home/sampleNews',params:{serviceNewsId:val}})
    // }
    
  },
  components: {
    BaiduMap,
  },
  
  
}
</script>

<style  scoped lang="less">
.content{width: 1440px; text-align: center; margin: auto;}
.el-image{width: 100%; text-align: center; }
.sciencSer{margin-top: 50px; width: 1440px;}
.inner{ display: inline-block; width: 350px; }
.innerChi{font-size: 24px; font-weight: bolder; color: rgb(136, 128, 14); }
.hr1{ display: inline; width: 480px; float: left; margin-left: 50px;margin-top: 60px;background-color: green;height:3px;border:none;}
.hr2{ display: inline;width: 480px;float:right; margin-right: 50px;margin-top: 60px;background-color: green;height:3px;border:none;/*filter: blur(1px);*/}
.innerEng{font-size: 16px; font-weight: normal;}
.sciencSerCont{margin-top: 60px; display: inline-block; }
.serLeft{width: 450px; float: left; margin-top: 20px; padding-left: 150px;}
.serLeft .el-image{float: left; height: 300px;}
.serRight{width: 500px; margin-right: 200px; float: right; margin-left: 100px; }
.serRight p{width: 480px; overflow: hidden;white-space: nowrap; text-overflow: ellipsis; text-align: left;margin-left: 40px;}
.numbRecord {margin-top: 80px;width: 1440px; margin: auto; margin-top: 50px;}
.numbRecord hr{width: 450px; }
/* .numbRecord p{font-size: 24px; font-weight: bolder; display: inline; } */
.copCompMap {margin-top: 100px;}
/* .copCompMap p{font-size: 24px; font-weight: bolder; display: inline; margin: 0 50px 0 50px;} */
.video{width: 900px; height: 450px; margin: 0 auto; margin-top: 80px;object-fit:fill;}
.copCompMap hr{width: 420px;}
.map{margin-top: 50px; margin-bottom: 50px;}
.bm-view {width: 100%;height: 400px; }
.el-carousel__container { height: 100% !important;}
a{text-decoration:none ; color: #000000; margin-bottom: 20px;}
.router-link-active {color: #0c6b11; float: left; width: 300px;}
.carous .el-carousel{padding-top: 0;height: 500px !important;}
.carous .el-carousel__container{height:500px !important;}
.carous .el-carousel__item{height:500px !important;}
.carous .el-carousel__item img{width:100%;height:500px;padding:0;border:0;z-index: -1;}
/deep/ .el-carousel__arrow.el-carousel__arrow--left{
  font-size: 50px !important;
  height: 30px !important;
  width: 30px !important;
  line-height: 200px
 }
/deep/ .el-carousel__arrow.el-carousel__arrow--right {
  font-size: 50px !important;
  height: 30px !important;
  width: 30px !important;
  line-height: 200px !important; 
}
// /deep/ .el-icon-arrow-left{color:cornflowerblue;}
</style>

