<template>
    <div  class="NewsList">
        <div class="planTable" v-for="data in tableData" :key="data.id">
              <router-link :to="'/home/sampleNews/'+data.serviceNewsId">{{data.serviceNewsTitle}}</router-link>
              <div class="postTime">{{data.serviceNewsTimeStr}}</div>
          </div>
        <!-- <ul class="left">
          <li class="Link">
            <router-link :to="'/home/sampleNews/'+data.serviceNewsId">{{data.serviceNewsTitle}}</router-link>
          </li>
       </ul> -->
       <!-- <ul class="right">
            <li>{{data.serviceNewsTimeStr}}</li>
        </ul> -->
      <div class="pagination">
        <el-pagination 
        background 
        :current-page="formLabelAlign.index"
        :page-size="formLabelAlign.pageSize"
        @current-change="handleCurrentChange" 
        :total="formLabelAlign.total"
        layout="total, prev, pager, next, jumper">
      </el-pagination>
      </div>
    </div>
</template>
<script>
import { request } from "@/network/request";
export default {
  props:['serviceNewsType'],
  data(){
    return {
      formLabelAlign:{
        index:1,
        pageSize:6,
        total:0,
      },
      NewsType:this.serviceNewsType,
      tableData:[],
    }
  },
   watch: {
        serviceNewsType(newVal) {
            this.NewsType = newVal //对父组件传过来的值进行监听，如果改变也对子组件内部的值进行改变
        }
    },
  mounted:function(){
    console.log(this.NewsType)
    this.getAllNews(this.formLabelAlign.index,this.formLabelAlign.pageSize,this.NewsType)
  },
  methods:{
    getAllNews(index,pageSize,serviceNewsType){
      let that = this;
      request({
        url: "/kjtpy/serviceNews/getAllNews",
        method: "post",
        data: {
          index:index,
          pageSize:pageSize,
          serviceNewsType:serviceNewsType
        }
      })
      .then((res) => {
        console.log(res)
        let data = res.data;
        if(data.code === 200){
          that.tableData = [];
          that.tableData = res.data.content.list; //深拷贝
          that.formLabelAlign.total = data.content.size
        }else{
          this.$message({
            message: data.content,
            type: 'warning',
            showClose: true
          });
        }
        
      })
      .catch((error) => {
        console.log(error);
      });
      },
       handleSizeChange(val) {
        this.formLabelAlign.pageSize = val
        this.formLabelAlign.index = 1
        this.getAllNews(this.formLabelAlign.index,this.formLabelAlign.pageSize,this.NewsType)
      },
      handleCurrentChange(val) {
        this.formLabelAlign.index = val
        this.getAllNews(this.formLabelAlign.index,this.formLabelAlign.pageSize,this.NewsType)
      }
  }
}
</script>

<style lang="scss" scoped>
  // .left , .right{
  //   li{
  //     display: block;
  //     text-align: left;
  //     padding: 10px 0;
  //   }
  // }
  // .NewsList{
  //   width: 100%;
  // }
  // .left{
  //   float: left;
  //   width: 60%;
  // }
  // .sample{
  // //  padding-bottom: 18px;
  //  border-bottom: 1px solid #e6e6e6;
  //  overflow: hidden;
  // }
  // .right{
  //   float: left;
  //   width: 30%;
  // }
  .pagination{
    padding-top: 10px;
    // float:left
  }
  a{cursor: pointer;outline: none;color: #393939;text-decoration: none;}
   .planTable{margin-bottom: 30px;padding: 10px; width: 90%;height: 60px;line-height: 60px; border-bottom:1px dashed rgb(233, 233, 233);border-left:1px solid rgb(233, 233, 233);text-align: left;}
  .planTable:hover{box-shadow:3px 3px 3px 3px #c8c8c8 ;}
  .postTitle{text-align: left;float: left;text-decoration: none;width: 80%;}
  a{color: #70a54d;font-weight: 1000;}
  a:hover {color:#398b03;text-decoration:underline;} /* ⿏标悬浮后的链接 */
  .postTime{text-align: right;color:rgb(175, 175, 175) ;width: 20%;float: right;}
</style>