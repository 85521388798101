<template>
  <div>
    <div class="position">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/backIndex/bScienServ' }">科技服务</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/backIndex/bScienServ' }">科技服务编辑</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="newstable">
      <el-table
      :data="tableData"
      style="width: 100%"
      :row-class-name="tableRowClassName"
       @selection-change="handleSelectionChange">
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column
        prop="serviceNewsTitle"
        label="新闻标题"
        width="500">
      </el-table-column>
      <el-table-column
        prop="serviceNewsTypeName"
        label="新闻类型"
        width="250">
      </el-table-column>
      <el-table-column
        prop="serviceNewsTimeStr"
        label="新闻时间"
        width="250">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" type="success" @click="handlePreview(scope.$index, scope.row)">预览</el-button>
          <el-button size="mini" type="primary" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
   <div class="Pagination">
      <el-pagination 
        background 
        :current-page="formLabelAlign.index"
        :page-size="formLabelAlign.pageSize"
        @current-change="handleCurrentChange" 
        :total="formLabelAlign.total"
        layout="total, prev, pager, next, jumper"></el-pagination>
        </div>
    </div>
  </div>
</template>

<script>
import { request } from "@/network/request";
export default {
  data () {
    return {
      tableData: [],
      formLabelAlign:{
        index:1,
        pageSize:10,
        total:0,
      },
    }
  },
  mounted:function(){
    this.getAllNews(this.formLabelAlign.index,this.formLabelAlign.pageSize)
  },
  methods: {
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 1) {
        return 'warning-row';
      } else if (rowIndex === 3) {
        return 'success-row';
      }
      return '';
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    /*调用接口函数*/ 

    // 获取所有新闻接口
    getAllNews(index,pageSize,serviceNewsType){
      let that = this;
      request({
        url: "/kjtpy/serviceNews/getAllNews",
        method: "post",
        data: {
          index:index,
          pageSize:pageSize,
          serviceNewsType:serviceNewsType
        }
      })
      .then((res) => {
        console.log(res)
        let data = res.data;
        if(data.code === 200){
          that.tableData = [];
          that.tableData = JSON.parse(JSON.stringify(res.data.content.list)); //深拷贝
          that.formLabelAlign.total = data.content.size
        }else{
          this.$message({
            message: data.content,
            type: 'warning',
            showClose: true
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
      },
    
    //删除新闻接口
    delete(val){
      request({
        url: '/kjtpy/serviceNews/deleteNews',
        method: 'post',
        data: {
          serviceNewsId:val
        }
        })
        .then(() => {
          this.getAllNews(this.formLabelAlign.index,this.formLabelAlign.pageSize);
        }).catch(err => {
          alert("删除失败")
      })
    },

    /*处理函数*/

    // 删除函数
    handleDelete(index,row){
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示",{
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() =>{
        console.log(row.serviceNewsId),
        this.delete(row.serviceNewsId)
      }).catch(() => {
        this.$message({
        type: "info",
        message: "已取消删除",})
      })
            
    },

    //编辑函数 
    handleEdit(index,row)
    {
      this.$router.push({name:'Newsupdate',params: {id:row.serviceNewsId}}) // 只能用 name
    },
    // 预览函数
    handlePreview(index,row){
      this.$router.push({name:'sampleNews',params: {id:row.serviceNewsId}}) // 只能用 name
    },
    //换页
    handleCurrentChange(val) {
      this.formLabelAlign.index = val
      this.getAllNews(this.formLabelAlign.index,this.formLabelAlign.pageSize)
    },
    // 页面大小
    handleSizeChange(val) {
      this.formLabelAlign.pageSize = val
      this.formLabelAlign.index = 1
      this.getAllNews(this.formLabelAlign.index,this.formLabelAlign.pageSize)
    },
    },
}
</script>

<style  scoped lang="less">
.el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
</style>
