<template>
  <el-container  class="tab">
    <div class="tabs">
        <el-tabs :tab-position="tabPosition"  @tab-click="handleClick" v-model="activeTab">
          <el-tab-pane label="服务纪实" name="serviceRecord"></el-tab-pane>
          <el-tab-pane label="创新创业" name="innovateEnterprise"></el-tab-pane>
          <el-tab-pane label="科技培训" name="scienTechTrain"></el-tab-pane>
        </el-tabs>
      </div>
    <el-main class="main">
      <div class="scienRecord">
        <div class="local">
        <i class="el-icon-location-information" ></i>
        <div>当前位置：科技培训</div>
        <div class="line"></div>
        </div>
        <div class="NewsList">
          <NewsList :serviceNewsType="formLabelAlign.serviceNewsType"></NewsList>
        </div>
        <!-- <div class="list" v-for="data in tableData" :key="data.id">
          <ul class="left">
              <li>{{data.serviceNewsTitle}}</li>
          </ul>
          <ul class="right">
            <li>{{data.serviceNewsTimeStr}}</li>
          </ul>
          
        </div>
        <el-pagination 
              background 
              :current-page="formLabelAlign.index"
              :page-size="formLabelAlign.pageSize"
              @current-change="handleCurrentChange" 
              :total="formLabelAlign.total"
              layout="total, prev, pager, next, jumper"></el-pagination> -->
      </div>
    </el-main>
  </el-container>
</template>

<script>
import { request } from "@/network/request";
import NewsList from "@/components/NewsList";
export default {
  name:'scienTechTrain',
  data () {
    return {
      formLabelAlign:{
        index:1,
        pageSize:6,
        serviceNewsType:1,
        total:0,
      },
      tableData:[],
      tabPosition: 'left',
      activeTab:'scienTechTrain'
    }
  },
   mounted:function(){
    // this.getAllNews(this.formLabelAlign.index,this.formLabelAlign.pageSize,this.formLabelAlign.serviceNewsType)
  },
  components:{
    NewsList
  },
  methods:{
    handleClick(tab,event){
            if(tab.label == '服务纪实'){
                this.$router.push({path:'/home/serviceRecord'})
            }
            else if(tab.label == '创新创业'){
                 this.$router.push({path:'/home/innovateEnterprise'})
            }
            else{
                this.$router.push({path:'/home/scienTechTrain'})
            }
      },
      // getAllNews(index,pageSize,serviceNewsType){
      //   let that = this;
      // request({
      //   url: "/kjtpy/serviceNews/getAllNews",
      //   method: "post",
      //   data: {
      //     index:index,
      //     pageSize:pageSize,
      //     serviceNewsType:serviceNewsType
      //   }
      // })
      // .then((res) => {
      //   console.log(res)
      //   let data = res.data;
      //   if(data.code === 200){
      //     that.tableData = [];
      //     that.tableData = res.data.content.list; //深拷贝
      //     that.formLabelAlign.total = data.content.size
      //   }else{
      //     this.$message({
      //       message: data.content,
      //       type: 'warning',
      //       showClose: true
      //     });
      //   }
        
      // })
      // .catch((error) => {
      //   console.log(error);
      // });
      
      // },
    // handleSizeChange(val) {
    //     this.formLabelAlign.pageSize = val
    //     this.formLabelAlign.index = 1
    //     this.getAllNews(this.formLabelAlign.index,this.formLabelAlign.pageSize,this.formLabelAlign.serviceNewsType)
    //   },
    //   handleCurrentChange(val) {
    //     this.formLabelAlign.index = val
    //     this.getAllNews(this.formLabelAlign.index,this.formLabelAlign.pageSize,this.formLabelAlign.serviceNewsType)
    //   }
      
  }
}
</script>

<style  scoped lang='less'>
  .tab{
    width: 1440px;
    margin:0 auto;
  }
   .tabs{ padding-top: 40px;height: 300px;width: 200px;float: left;margin-left: 3%;}
  /deep/ .el-tabs__item{height: 50px;font-size: 16px;font-weight: 400;color: #172F31;}
  /deep/ .el-tabs__item:hover{color: #BFD9DA;}
  /deep/ .el-tabs__item.is-active{color: #70A54D;}
  /deep/ .el-tabs__active-bar{background: #70A54D;height: 50px;}
  .scienRecord{
    width: 100%;
  }
  .local{
    width: 100%;
    height: 30px;
  }
   .NewsList{
    height: 700px;
    width: 100%;
  }
  .scienRecord i{
    float: left;
    width: 4%;
    }
  .scienRecord div{
    float: left;
    margin-bottom: 1%;
    }
  .scienRecord .line{
    float: left;
    width: 100%;
    height: 2%;
    background-color:#70A54D;
  }
  .el-button{
    float: right;
    margin-right: 4%;
  }
  
</style>
