<template>
  <div>
    <el-container>
      <el-header
        style="
          float: clear;
          height: 100px;
          margin-top: 0px;
          font-size: 28px;
          color: white;
          text-align: left;
          line-height: 100px;
        "
      >
        科技特派员管理平台
        <img
          src="@/assets/img/change.png"
          width="20px"
          style="float: right; margin: 37px"
          @click="change()"
        />
        <router-link
          :to="{ path: '/home/userLogin' }"
          style="float: right; font-size: 18px; color: white; text-align: right"
          @click.native="exit"
          >退出</router-link
        >
      </el-header>
      <el-container style="height: 100%">
        <el-aside style="width: 230px">
          <el-col style="height: 1080px; background-color: #164902">
            <el-menu
              router
              default-active="2"
              class="el-menu-vertical-demo"
              style="margin: 0px; border: 0px; padding: 0px"
              background-color="#214b2b"
              text-color="#fff"
              active-text-color="#28751d"
            >
              <el-submenu
                index="/backIndex/bSystemDisplay"
                class="active"
                style="
                  list-style: none;
                  padding: 5px 0px;
                  text-align: center;
                  font-size: 20px;
                "
              >
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span slot="menu" style="text-align: center; font-size: 20px"
                    >成果展示</span
                  >
                </template>
                <el-menu-item
                  index="/backIndex/bSystemDisplay"
                  class="active"
                  style="
                    list-style: none;
                    padding: 5px 0px;
                    text-align: center;
                    font-size: 14px;
                  "
                >
                  <span slot="title" style="margin-left: 30px">系统展示</span>
                </el-menu-item>
                <el-menu-item
                  index="/backIndex/bInvenPatent"
                  class="active"
                  style="
                    list-style: none;
                    padding: 5px 0px;
                    text-align: center;
                    font-size: 14px;
                  "
                >
                  <span slot="title" style="margin-left: 30px">发明专利</span>
                </el-menu-item>
                <el-menu-item
                  index="/backIndex/bTechAchieve"
                  class="active"
                  style="
                    list-style: none;
                    padding: 5px 0px;
                    text-align: center;
                    font-size: 14px;
                  "
                >
                  <span slot="title" style="margin-left: 60px"
                    >创新技术及成果</span
                  >
                </el-menu-item>
              </el-submenu>
              <!-- <el-menu-item
                index="/backIndex/bSystemDisplay"
                class="active"
                style="
                  list-style: none;
                  padding: 5px 0px;
                  text-align: center;
                  font-size: 20px;"
              >
                <i class="el-icon-menu"></i>
                <span slot="title" style="text-align: center">系统展示</span>
              </el-menu-item> -->
              <el-submenu
                index="/backIndex/bScienServ"
                class="active"
                style="
                  list-style: none;
                  padding: 5px 0px;
                  text-align: center;
                  font-size: 20px;
                "
              >
                <template slot="title">
                  <i class="el-icon-document"></i>
                  <span slot="title" style="text-align: center; font-size: 20px"
                    >科技服务</span
                  >
                </template>
                <el-menu-item
                  index="/backIndex/bScienServ"
                  class="active"
                  style="
                    list-style: none;
                    padding: 5px 0px;
                    text-align: center;
                    font-size: 14px;
                  "
                >
                  <span slot="title" style="margin-left: 50px">新闻展示</span>
                </el-menu-item>
                <el-menu-item
                  index="/backIndex/bScienServEdit"
                  class="active"
                  style="
                    list-style: none;
                    padding: 5px 0px;
                    text-align: center;
                    font-size: 14px;
                  "
                >
                  <span slot="title" style="margin-left: 50px"
                    >新闻编辑</span
                  >
                </el-menu-item>
              </el-submenu>
              <!-- <el-menu-item
                index="/backIndex/bScienServ"
                class="active"
                style="
                  list-style: none;
                  padding: 5px 0px;
                  text-align: center;
                  font-size: 20px;"
              >
                <i class="el-icon-document"></i>
                <span slot="title">科技服务</span>
              </el-menu-item> -->
              <el-menu-item
                index="/backIndex/bWorkPlan"
                class="active"
                style="
                  list-style: none;
                  padding: 5px 0px;
                  text-align: center;
                  font-size: 20px;
                "
              >
                <i class="el-icon-time"></i>
                <span slot="title">工作进展与计划</span>
              </el-menu-item>
              <el-menu-item
                index="/backIndex/bWorkSite"
                class="active"
                style="
                  list-style: none;
                  padding: 5px 0px;
                  text-align: center;
                  font-size: 20px;
                "
              >
                <i class="el-icon-orange"></i>
                <span slot="title">工作站点</span>
              </el-menu-item>
            </el-menu>
          </el-col>
        </el-aside>
        <el-main>
          <!-- 修改弹框 -->
          <el-dialog
            title="修改账号密码"
            :visible.sync="FormVisible"
            :show-close="false"
            :close-on-click-modal="false"
          >
            <el-form :model="Form">
              <el-form-item
                label="用户名"
                :label-width="formLabelWidth"
                prop="name"
              >
                <el-input
                  v-model="Form.adminName"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="电话"
                :label-width="formLabelWidth"
                prop="tele"
              >
                <el-input
                  v-model="Form.adminTelephone"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="邮箱"
                :label-width="formLabelWidth"
                prop="email"
              >
                <el-input
                  v-model="Form.adminEmail"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="新密码"
                :label-width="formLabelWidth"
                prop="password"
              >
                <el-input
                  v-model="Form.adminPassword"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="editBack()">取 消</el-button>
              <el-button type="primary" @click="submit()">确 定</el-button>
            </div>
          </el-dialog>

          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import { request } from "@/network/request";
import md5 from 'js-md5'
export default {
  data() {
    return {
      FormVisible: false,
      Form: {
        adminId: "",
        adminName: "",
        adminTelephone: "",
        adminEmail: "",
        adminPassword: "",
      },

      formLabelWidth: "120px",
      msgId: "",
    };
  },
  methods: {
    change() {
      this.msgId = JSON.parse(sessionStorage.getItem("adminId"));
      request({
        url: "/kjtpy/admin/getAdmin",
        method: "post",
        data: {
          adminId: this.msgId,
        },
      })
        .then((res) => {
          this.Form.adminId=res.data.content.adminId;
          this.Form.adminName=res.data.content.adminName ;
          this.Form.adminTelephone=res.data.content.adminTelephone;
          this.Form.adminEmail=res.data.content.adminEmail;
        })
        .catch((err) => {});
      this.FormVisible = true;
    },
    editBack() {
      this.FormVisible = false;
    },
    submit() {
      let data = Object.assign({}, this.Form);
      request({
        url: "/kjtpy/admin/update",
        method: "post",
        data: { 
          adminId:data.adminId,
          adminName:data.adminName,
          adminTelephone:data.adminTelephone,
          adminEmail:data.adminEmail,
          adminPassword:md5(data.adminPassword)
         },
      })
        .then((res) => {
          this.$router.push({path:'/home/userLogin'})
          this.$message({
            message: "修改成功!",
            type: 'warning',
            showClose: true
          });
        })
        .catch((err) => {});
    },
  },
};
</script>

<style  scoped>
.el-header {
  background-color: #70a54d;
  color: #333;
  line-height: 70px;
}
.active:hover {
  color: #054113;
  background-color: rgb(22, 73, 2);
}
</style>
