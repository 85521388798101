<template>
<div class="news">
  <h2>{{list.patentName}}</h2>
  <el-image :src="list.patentPicture" v-show="isPicture"></el-image>
  <p>{{list.patentIntroduce}}</p>
</div>
</template>
<script>
import { request } from "@/network/request";
export default {
  data(){
    return {
      list:[],
      isPicture:true,
    }
  },
  methods:{
    getOnePatent(val){
      let that = this;
      request({
        url: "/kjtpy/inventionPatent/getOnePatent",
        method: "post",
        data: {
          id:val
        }
      })
      .then((res) => {
        console.log(res)
        let data = res.data;
        if(data.code === 200){
          that.list = [];
          that.list = res.data.content[0]; //深拷贝
          if(that.list.patentPicture === ''){
            that.isPicture = false;
          }
          console.log(that.isPicture)
        }else{
          this.$message({
            message: data.content,
            type: 'warning',
            showClose: true
          });
        }
        
      })
      .catch((error) => {
        console.log(error);
      });
    }
  },
  mounted:function(){
    this.getOnePatent(this.$route.params.id)
  }
}
</script>
<style  scoped lang="less">
 .news{
    padding: 0 15px;
    width: 1200px;
    margin: 0 auto;
    min-height: 620px;
  }
  .news p{
    text-align: left;
    text-indent: 2em;
    // font-size: 17px;
    line-height:25px;
  }
  
</style>