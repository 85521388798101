<<<<<<< HEAD
<template>
  <div class="main">
    <div class="carous">
      <el-carousel  :interval="4000" >
      <el-carousel-item v-for="item in carouseImg" :key="item.url">
        <img 
          :src="item.url" alt></img>
      </el-carousel-item>
      </el-carousel>
    </div>
    

    <div class="content">
      <div class="contact">
        <hr class="hr1"/>
        <p>联系我们</p>
        <hr class="hr2"/>
      </div>

      <div class="contactUs">
        <div class="left">
          <div class="text">
          <i class="icon1"></i>
          <img src="../../assets/img/iconphone.png"/>
          <h3 class="myh1">联系方式</h3>
          <p class="myp1">
            027-67842781
          </p>
        </div>
        </div>
        <div class="mid">
          <img src="../../assets/img/iconmail.png"/>
          <h3 class="myh1">邮箱</h3>
          <p>kjtpy2781@163.com</p>
        </div>
        <div class="right">
          <img src="../../assets/img/iconTech.png"/>
          <h3 class="myh1">技术支持</h3>
          <p>湖北省制造企业智能管理工程技术研究中心</p>
        </div>
      </div>

      <div class="map">
        <baidu-map :center="center"
          :zoom="zoom"
          class="bm-view"
          @ready="handler"
          :scroll-wheel-zoom="true"
          ak="YhbSXlFYifR1w6NGSX2ONl8GTOtZgcl4">
            <bm-marker :position="{lng: 114.396554, lat: 30.49558}" :dragging="true">
              <!-- <bm-label content="中南民族大学九号教学楼" :labelStyle="{color: 'red', fontSize : '20px'}" :offset="{width: 25, height: 10}"/> -->
            </bm-marker>
          </baidu-map>
      </div>
    </div>

    <div class="link">
      <p class="title">友情链接</p>
      <div class="line">
        <p class="linelink"><a target="_blank" href="https://www.neac.gov.cn/">国家民委</a></p>
        <p class="linelink"><a target="_blank" href="https://www.hubei.gov.cn/">湖北省人民政府</a></p>
        <p class="linelink"><a target="_blank" href="http://kjt.hubei.gov.cn/">湖北省科学技术厅</a></p>
      </div>
      
      <!-- <div class="left1">
        <p class="title">友情链接</p>
        <div class="innerlink">
          <div class="left2">
            <p><a target="_blank" href="https://www.neac.gov.cn/">国家民委</a></p>
            <p><a target="_blank" href="https://www.hubei.gov.cn/">湖北省人民政府</a></p>
            <p><a target="_blank" href="http://www.st.scuec.edu.cn/">科学技术处</a></p>
          </div>
          <div class="mid2">
            <p><a target="_blank" href="https://www.scuec.edu.cn/">中南民族大学</a></p>
            <p><a target="_blank" href="http://kjt.hubei.gov.cn/">湖北省科学技术厅</a></p>
            <p><a target="_blank" href="https://znglzx.scuec.edu.cn/">工程中心学生管理系统</a></p>
          </div>
          <div class="right2">
            <p><a target="_blank" href="https://new-thread.com/">新思路团队</a></p>
            <p><a target="_blank" href="https://www.scuec.edu.cn/jky/">计算机科学学院</a></p>
          </div>
        </div>
      </div>
      <div class="mid1">
        <p class="title">联系我们</p>
        <p>电话：027-67842781</p>
        <p>传真：027-67842781</p>
        <p>地址：中国.湖北.武汉.民族大道182号</p>

      </div>
      <div class="right1">
        <p class="title">合作信息</p>
        <a target="_blank" href="https://znglzx.scuec.edu.cn/#"><p>基于互联网管理的农业控制及制造业</p></a>
      </div> -->
    </div>
  </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'

export default {
  data () {
    return {
      carouseImg:[
        {url: require("../../assets/img/carous1.jpg")},
        {url: require("../../assets/img/carous2.jpg")},
        {url: require("../../assets/img/carous6.png")}
      ],
      center: {lng:0,lat: 0},
      zoom: 3,

    }
  },
  methods: {
    handler({BMap, map}) {
      this.center.lng = 114.39345458960705;
      this.center.lat = 30.4954068141862;
      this.zoom = 15;
    }
  },
  components: {
    BaiduMap
  }
}
</script>

<style  scoped lang="less">
.content{width: 1440px; text-align: center; margin: auto;}
.contact{margin-top: 100px;}
.contact p{font-size: 24px; font-weight: bolder; display: inline;  line-height: 15px;}
.hr1{ display: inline; width: 400px; float: left; margin-left: 200px;margin-top: 10px; background-color: green;height:2px;border:none;}
.hr2{ display: inline;width: 400px;float:right; margin-right: 200px;background-color: green;height:2px;border:none;}
.contactUs{margin-top: 50px; display: inline-block;}
.left{width: 250px; height: 200px; background-color: rgb(238, 235, 235); float: left; margin-left: 50px; border-radius: 5px;}
.mid{width: 250px; height: 200px; background-color: rgb(238, 235, 235);float: left;margin-left: 80px;border-radius: 5px;}
.right{width: 250px; height: 200px; background-color: rgb(238, 235, 235);float: left;margin-left: 80px; margin-right: 50px;border-radius: 5px; padding: 0 10px 0 10px;}
.contactUs img{width: 40px; height: 40px; margin-top: 30px;}
.map{height: 500px; margin-top: 50px;}
.bm-view {width: 100%;height: 400px;}
.link{width: 1440px; height: 200px; background-color: rgb(247, 245, 245); border-top: 1px solid rgb(6, 126, 6); margin: 0 auto; display: inline-block;}
.linelink{display: inline-block; margin-left: 250px;float: left; text-align: left;font-size: 18px;}
.left1{width: 600px;float: left; text-align: left;}
.mid1{width: 400px;float: left; text-align: left;}
.right1{width: 400px;float: left; text-align: left;}
.innerlink{width: 600px; display: inline-block; }
.left2,.right2,.mid2{width: 160px;float: left; text-align: left; margin-left: 10px; }
.left2 a,.right2 a,.mid2 a,.right1 a,.line a{text-decoration: none; color: black; }
.title{text-align: left; font-size: 20px; margin-left: 150px; margin-bottom: 20px; font-weight: bold;}
.carous{padding-top: 0px; margin-top: 0;}
.carous .el-carousel{padding-top: 0;height: 500px !important;}
.carous .el-carousel__container{height:500px !important;}
.carous .el-carousel__item{height:500px !important;}
.carous .el-carousel__item img{width:100%;height:500px;padding:0;}
/deep/ .el-carousel__arrow.el-carousel__arrow--left{font-size: 50px !important;line-height: 200px !important; }
/deep/ .el-carousel__arrow.el-carousel__arrow--right {  font-size: 50px !important;  height: 30px !important;  width: 30px !important;  line-height: 200px !important; }
/deep/ .el-icon-arrow-left{list-style: none;}
/deep/ .el-carousel__arrow i{list-style-type:none;}
</style>
=======
<template>
  <div class="main">
    <div class="carous">
      <el-carousel  :interval="4000" >
      <el-carousel-item v-for="item in carouseImg" :key="item.url">
        <img 
          :src="item.url" alt></img>
      </el-carousel-item>
      </el-carousel>
    </div>
    

    <div class="content">
      <div class="contact">
        <hr class="hr1"/>
        <p>联系我们</p>
        <hr class="hr2"/>
      </div>

      <div class="contactUs">
        <div class="left">
          <div class="text">
          <i class="icon1"></i>
          <img src="../../assets/img/iconphone.png"/>
          <h3 class="myh1">联系方式</h3>
          <p class="myp1">
            027-67842781
          </p>
        </div>
        </div>
        <div class="mid">
          <img src="../../assets/img/iconmail.png"/>
          <h3 class="myh1">邮箱</h3>
          <p>kjtpy2781@163.com</p>
        </div>
        <div class="right">
          <img src="../../assets/img/iconTech.png"/>
          <h3 class="myh1">技术支持</h3>
          <p>湖北省制造企业智能管理工程技术研究中心</p>
        </div>
      </div>

      <div class="map">
        <baidu-map :center="center"
          :zoom="zoom"
          class="bm-view"
          @ready="handler"
          :scroll-wheel-zoom="true"
          ak="YhbSXlFYifR1w6NGSX2ONl8GTOtZgcl4">
            <bm-marker :position="{lng: 114.396554, lat: 30.49558}" :dragging="true">
              <!-- <bm-label content="中南民族大学九号教学楼" :labelStyle="{color: 'red', fontSize : '20px'}" :offset="{width: 25, height: 10}"/> -->
            </bm-marker>
          </baidu-map>
      </div>
    </div>

    <div class="link">
      <p class="title">友情链接</p>
      <div class="line">
        <p class="linelink"><a target="_blank" href="https://www.neac.gov.cn/">国家民委</a></p>
        <p class="linelink"><a target="_blank" href="https://www.hubei.gov.cn/">湖北省人民政府</a></p>
        <p class="linelink"><a target="_blank" href="http://kjt.hubei.gov.cn/">湖北省科学技术厅</a></p>
      </div>
      
      <!-- <div class="left1">
        <p class="title">友情链接</p>
        <div class="innerlink">
          <div class="left2">
            <p><a target="_blank" href="https://www.neac.gov.cn/">国家民委</a></p>
            <p><a target="_blank" href="https://www.hubei.gov.cn/">湖北省人民政府</a></p>
            <p><a target="_blank" href="http://www.st.scuec.edu.cn/">科学技术处</a></p>
          </div>
          <div class="mid2">
            <p><a target="_blank" href="https://www.scuec.edu.cn/">中南民族大学</a></p>
            <p><a target="_blank" href="http://kjt.hubei.gov.cn/">湖北省科学技术厅</a></p>
            <p><a target="_blank" href="https://znglzx.scuec.edu.cn/">工程中心学生管理系统</a></p>
          </div>
          <div class="right2">
            <p><a target="_blank" href="https://new-thread.com/">新思路团队</a></p>
            <p><a target="_blank" href="https://www.scuec.edu.cn/jky/">计算机科学学院</a></p>
          </div>
        </div>
      </div>
      <div class="mid1">
        <p class="title">联系我们</p>
        <p>电话：027-67842781</p>
        <p>传真：027-67842781</p>
        <p>地址：中国.湖北.武汉.民族大道182号</p>

      </div>
      <div class="right1">
        <p class="title">合作信息</p>
        <a target="_blank" href="https://znglzx.scuec.edu.cn/#"><p>基于互联网管理的农业控制及制造业</p></a>
      </div> -->
    </div>
  </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'

export default {
  data () {
    return {
      carouseImg:[
        {url: require("../../assets/img/carous1.jpg")},
        {url: require("../../assets/img/carous2.jpg")},
        {url: require("../../assets/img/carous6.png")}
      ],
      center: {lng:0,lat: 0},
      zoom: 3,

    }
  },
  methods: {
    handler({BMap, map}) {
      this.center.lng = 114.39345458960705;
      this.center.lat = 30.4954068141862;
      this.zoom = 15;
    }
  },
  components: {
    BaiduMap
  }
}
</script>

<style  scoped lang="less">
.content{width: 1440px; text-align: center; margin: auto;}
.contact{margin-top: 100px;}
.contact p{font-size: 24px; font-weight: bolder; display: inline;  line-height: 15px;}
.hr1{ display: inline; width: 400px; float: left; margin-left: 200px;margin-top: 10px; background-color: green;height:2px;border:none;}
.hr2{ display: inline;width: 400px;float:right; margin-right: 200px;background-color: green;height:2px;border:none;}
.contactUs{margin-top: 50px; display: inline-block;}
.left{width: 250px; height: 200px; background-color: rgb(238, 235, 235); float: left; margin-left: 50px; border-radius: 5px;}
.mid{width: 250px; height: 200px; background-color: rgb(238, 235, 235);float: left;margin-left: 80px;border-radius: 5px;}
.right{width: 250px; height: 200px; background-color: rgb(238, 235, 235);float: left;margin-left: 80px; margin-right: 50px;border-radius: 5px; padding: 0 10px 0 10px;}
.contactUs img{width: 40px; height: 40px; margin-top: 30px;}
.map{height: 500px; margin-top: 50px;}
.bm-view {width: 100%;height: 400px;}
.link{width: 1440px; height: 200px; background-color: rgb(247, 245, 245); border-top: 1px solid rgb(6, 126, 6); margin: 0 auto; display: inline-block;}
.linelink{display: inline-block; margin-left: 250px;float: left; text-align: left;font-size: 18px;}
.left1{width: 600px;float: left; text-align: left;}
.mid1{width: 400px;float: left; text-align: left;}
.right1{width: 400px;float: left; text-align: left;}
.innerlink{width: 600px; display: inline-block; }
.left2,.right2,.mid2{width: 160px;float: left; text-align: left; margin-left: 10px; }
.left2 a,.right2 a,.mid2 a,.right1 a,.line a{text-decoration: none; color: black; }
.title{text-align: left; font-size: 20px; margin-left: 150px; margin-bottom: 20px; font-weight: bold;}
.carous{padding-top: 0px; margin-top: 0;}
.carous .el-carousel{padding-top: 0;height: 500px !important;}
.carous .el-carousel__container{height:500px !important;}
.carous .el-carousel__item{height:500px !important;}
.carous .el-carousel__item img{width:100%;height:500px;padding:0;}
/deep/ .el-carousel__arrow.el-carousel__arrow--left{font-size: 50px !important;line-height: 200px !important; }
/deep/ .el-carousel__arrow.el-carousel__arrow--right {  font-size: 50px !important;  height: 30px !important;  width: 30px !important;  line-height: 200px !important; }
/deep/ .el-icon-arrow-left{list-style: none;}
/deep/ .el-carousel__arrow i{list-style-type:none;}
</style>
>>>>>>> bd490359bfad6c4c9a7cfbf619e7591a02e93ba3
