<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/backIndex/bSystemDisplay' }">科技成果</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/backIndex/bTechAchieve' }">创新技术及成果</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="table">
      <el-button type="primary" style="float:right" size="small"  @click="openAddDialog()">新增</el-button>
      <el-table
      :data="tableData"
      stripe
      style="width: 100%">
      <el-table-column
        prop="achievementsName"
        label="创新技术及成果名称" align="center"
        width="300">
      </el-table-column>
      <el-table-column
        prop="achievementsPicture"
        label="创新技术及成果图片" align="center"
        width="300">
        <template slot-scope="scope">
          <el-button  type="success" size="mini" @click="showImg(scope.row.achievementsPicture)">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="achievementsIntroduce"
        label="创新技术及成果介绍" align="center"
        width="480">
      </el-table-column>
      <el-table-column
        prop="option"
        label="操作" align="center"
        >
        <template slot-scope="scope">
          <el-button  type="primary" size="mini" @click="openEditDialog(scope.row)">编辑</el-button>
          <el-button  type="warning" size="mini" @click="deleteSyst(scope.row)">删除</el-button>
        </template>
        </el-table-column>
        </el-table>
    </div>

    <div class="Pagination">
      <el-pagination 
        background 
        layout="total,sizes,prev, pager, next,jumper"  
        :current-page="formLabelAlign.index"
        :page-sizes="[10, 20, 30, 40,50,60]"
        :page-size="formLabelAlign.pageSize" 
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange" 
        :total="total"></el-pagination>
    </div>

    <!-- 新增对话框 -->
    <el-dialog title="新增" :visible.sync="addFormVisible " >
      <el-form :model="addForm" ref="addForm">
          <el-form-item label="创新技术及成果名称" :label-width="formLabelWidth" prop="achievementsName">
              <el-input v-model="addForm.achievementsName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="创新技术及成果介绍" :label-width="formLabelWidth" prop="achievementsIntroduce">
              <el-input v-model="addForm.achievementsIntroduce" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="创新技术及成果图片" prop="achievementsPicture" style="margin-left:50px;" align="left">
              <el-upload
                  action=""
                  list-type="picture-card"
                  name="files"
                  ref="uploadImg"
                  :limit="1"
                  :auto-upload = "false"
                  :headers = "headers"
                  :on-change="changeImg"
                  :on-success="
                      (res, file) =>
                      handleAvatarSuccess(res, file, 'addForm', 'achievementsPicture')
                  "
                  :on-preview="
                      (file) =>
                      handlePictureCardPreview(file)
                  "
                  :on-remove="handleRemove"
                  :before-upload="beforeAvatarUpload"
                  >
                      <i class="el-icon-plus"></i>
                  </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="addForm.achievementsPicture" alt="" />
              </el-dialog>
          </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
          <el-button @click="addCarcer()">取 消</el-button>
          <el-button type="primary" @click="handleSubmit('addForm')">确 定</el-button>
      </div>
  </el-dialog>
<!-- 编辑 -->
  <el-dialog title="编辑" :visible.sync="editFormVisible">
    <el-form :model="editForm" ref="editForm">
    <el-form-item label="创新技术及成果名称" :label-width="formLabelWidth" prop="achievementsName">
        <el-input v-model="editForm.achievementsName" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="创新技术及成果介绍" :label-width="formLabelWidth" prop="achievementsIntroduce">
        <el-input v-model="editForm.achievementsIntroduce" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="创新技术及成果图片" prop="achievementsPicture" style="margin-left:50px;" align="left">
        <el-upload
            action=""
            list-type="picture-card"
            ref="uploadImg"
            name="files"
            :file-list="fileList"
            :limit="1"
            :headers = "headers"
            :auto-upload = "false"
            :on-success="
                (res, file) =>
                handleAvatarSuccess(res, file, 'editForm', 'achievementsPicture')
            "
            :on-change="changeImg"
            :on-preview="
                (file) =>
                handlePictureCardPreview(file)
            "
            :on-remove="handleRemoveEdit"
            :before-upload="beforeAvatarUpload"
            >
                <!-- <i class="el-icon-plus"></i> -->
                <img width="100%" class="el-icon-plus" :src="editForm.achievementsPicture" alt=""  style="width:100%;height:100%"/>

            </el-upload>
        <!-- <el-dialog :visible.sync="dialogVisible"> -->
          <!-- <img width="100%"  :src="editForm.achievementsPicture" alt="" /> -->
        <!-- </el-dialog> -->
    </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
          <el-button  @click="editCarcer()">取 消</el-button>
          <el-button type="primary" @click="handleSubmit('editForm')">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog min-width="200px" title="系统图片" :visible.sync="dialogLookVisible" center>
      <div align="center">
          <img :src="pictureUrl" width="80%" style="margin: auto; height: 600px"/>
      </div>
      <p style="text-align: center; padding-top: 10px; color: red" v-show="lookPictureFlag">暂无图片</p>
    </el-dialog>
  </div>
</template>

<script>
import { request } from "@/network/request"
import { MessageBox } from 'element-ui';

export default {
  inject:['reload'],
  data () {
    return {
      dialogVisible: false,
      addFormVisible: false,
      editFormVisible: false,
      formLabelWidth:'150px',
      dialogLookVisible: false,
      lookPictureFlag: false,
      pictureUrl: "",
      fileList: [],
      addForm: {
        achievementsName: '',
        achievementsPicture: '',
        achievementsIntroduce: '',
      },
      editForm: {
        achievementsName: '',
        achievementsPicture: '',
        achievementsIntroduce: '',
      },
      formLabelAlign: {
        index:1,
        pageSize:10
      },
      tableData: [],
      total: 0,
      headers: {
         Authorization: sessionStorage.getItem('token')
      },
      editId:'',   //为了编辑的时候传参以记录每条的id
      files:{}
    }
  },
  mounted(){
    this.showAll();
  },
  methods: {
    addCarcer(){
      this.addFormVisible = false;
      if(this.addForm.achievementsPicture != null){
        request({
          url: '/kjtpy/img/deletePicture',
          method: 'post',   
          data: [this.addForm.achievementsPicture]
        }).then(res => {
          console.log("add-删除成功")
        })
      }
    },
    // 取消按钮
    editCarcer(){
      this.editFormVisible = false;
      this.reload()
    },
    // 打开添加对话框
    openAddDialog() {
        this.addForm = {};
        this.addFormVisible = true;
        this.$nextTick(() => {
            this.$refs.addForm.clearValidate();
        });
    },
    //打开编辑对话框
    openEditDialog(val) {
      // let data = Object.assign({}, val);
      // this.editForm = data;
      this.editId = val.id;
      this.editForm.achievementsName = val.achievementsName,
      this.editForm.achievementsPicture = val.achievementsPicture,
      this.editForm.achievementsIntroduce = val.achievementsIntroduce
      this.editFormVisible = true;
      this.$nextTick(() => {
          this.$refs.editForm.clearValidate();
      });
    },
    deleteSyst(row){
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示",{
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() =>{
        this.delete(row.id)
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        })
      })
    },
    showAll(){
      request({
        url: '/kjtpy/achievement/getAllAchievement',
        method: 'post',
        data: {
          index: this.formLabelAlign.index,
          pageSize: this.formLabelAlign.pageSize,
        }
      }).then(res => {
        if(res.data.code == 200){
          this.tableData = [],
          this.tableData = res.data.content.data,
          this.total = res.data.content.total
        }
      })
    },
    handleSubmit(form){
      this.$refs[form].validate(valid => {
        if(valid) {
          if(form == 'addForm'){
            this.confirmAdd()
          }
          if(form == 'editForm'){
            this.confirmEdit()
          }
        }else {
          this.$message({
            type: "warning",
            message: "您填写的信息有误，请重新检查",
          });
          return false;
        }
      })
    },
    confirmAdd(){
      // let data = Object.assign({},this.addForm);
      let that = this;
      // console.log(that.file)
      console.log(JSON.stringify(that.files))
      if(JSON.stringify(that.files) === '{}'){
        request({
            url: '/kjtpy/achievement/addAchievement',
            method: 'post',
            data: {
              achievementsName: that.addForm.achievementsName,
              achievementsPicture: '',
              achievementsIntroduce: that.addForm.achievementsIntroduce
            }
          }).then(res => {
            if(res.data.code == 200){
              this.addFormVisible=false;
              MessageBox('添加成功');
              this.$refs.uploadImg.clearFiles()
              this.showAll();
            }
          })
      }else{
        const formData = new FormData();
        formData.append("files",that.files)
        request({
          url: '/kjtpy/img/uploadPicture',
          method: 'post',
          headers:{"Content-Type": "multipart/form-data",},
          data: formData
        }).then(res => {
          if(res.data.code == 200){
            console.log("res")
            that.addForm.achievementsPicture=res.data.content
            request({
              url: '/kjtpy/achievement/addAchievement',
              method: 'post',
              data: {
                achievementsName: that.addForm.achievementsName,
                achievementsPicture: (that.addForm.achievementsPicture).toString(),
                achievementsIntroduce: that.addForm.achievementsIntroduce
              }
            }).then(res => {
              if(res.data.code == 200){
                this.addFormVisible=false;
                MessageBox('添加成功');
                this.$refs.uploadImg.clearFiles()
                this.showAll();
              }
            })
          }
        })
      } 
    },
    confirmEdit(){
      // let data = Object.assign({},this.editForm);
      let that = this;

      // console.log(that.file)
      // console.log(that.files)
      console.log(JSON.stringify(that.files))
      // console.log(typeof(that.file.raw))

      if(JSON.stringify(that.files) === '{}'){
        // console.log(111)
        request({
          url: '/kjtpy/achievement/updateAchievement',
          method: 'post',
          data: {
            id: this.editId,
            achievementsName: that.editForm.achievementsName,
            achievementsPicture: '',
            achievementsIntroduce: that.editForm.achievementsIntroduce
          }
        }).then(res => {
          if(res.data.code == 200){
            console.log(222)
            this.editFormVisible = false;
            this.$refs.uploadImg.clearFiles()
            this.showAll()
          }
        })
      }else{
        const formData = new FormData();
        formData.append("files",that.files)
        // 先删除之前的图片，再上传图片，再更新这条数据
        request({
          url: '/kjtpy/img/deletePicture',
          method: 'post',
          data: [this.editForm.achievementsPicture]
        }).then(res => {
          if(res.data.code == 200){
            console.log("shanchu")
            this.editForm.achievementsPicture = ""
            request({
              url: '/kjtpy/img/uploadPicture',
              method: 'post',
              headers: {
                "Content-Type": "multipart/form-data"
              },
              data : formData
            }).then(res => {
              console.log("upload")
              that.editForm.achievementsPicture = res.data.content
                request({
                  url: '/kjtpy/achievement/updateAchievement',
                  method: 'post',
                  data: {
                    id: this.editId,
                    achievementsName: that.editForm.achievementsName,
                    achievementsPicture: (that.editForm.achievementsPicture).toString(),
                    achievementsIntroduce: that.editForm.achievementsIntroduce
                  }
                }).then(res => {
                  if(res.data.code == 200){
                    this.editFormVisible = false;
                    this.$refs.uploadImg.clearFiles()
                    this.showAll()
                  }
                })
            })
          }
        })
      }
      
       
      
    },
    delete(val){
      request({
        url: '/kjtpy/achievement/deleteAchievement',
        method: 'post',
        data: {
          id: val
        }
      }).then(res => {
        this.showAll();
      })
    },
    // 查看图片
    showImg(val){
      this.dialogLookVisible = true;
      if(val != "" && val != undefined) {
        this.pictureUrl = val;
        this.lookPictureFlag = false;
      } else {
        this.pictureUrl = "";
        this.lookPictureFlag = true;
      }
    },
    changeImg(file,fileList){
      // console.log(file.raw)
      this.files = file.raw
      // console.log(this.files)
    },
    // 图片上传成功函数
    handleAvatarSuccess(res, file, form, type) {
    if (res.code == "403") {
        this.$router.push("/");
    } else {
        this.addForm.achievementsPicture = res.content[0];
        console.log("赋值成功");
        console.log(res.content[0]);
    }
    },
    // 图片预览函数
    handlePictureCardPreview(file) {
    this[form][type] = file.url;
    this.dialog.dialogVisible = true;
    },
    // 图片移除操作
    handleRemove(file, fileList) {
    this.uploadDisabled = false;
    this.editForm.achievementsPicture = ""
    },
    handleRemoveEdit(){
      this.uploadDisabled = false;
      request({
        url: '/kjtpy/img/deletePicture',
        method: 'post',
        data: [this.editForm.achievementsPicture]
      }).then(res => {
        if(res.data.code == 200){
          this.editForm.achievementsPicture = ""
          // this.handleSubmit('editForm')
          console.log("删除成功")
          let data = Object.assign({},this.editForm);
          request({
            url: '/kjtpy/achievement/updateAchievement',
            method: 'post',
            data: data
          }).then(res => {
            if(res.data.code == 200){
              this.showAll()
            }
          })
        }
      }).catch(res => {

      })
    },
    // 图片上传前
    beforeAvatarUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
      this.$message.error('上传图片大小不能超过 512KB!');
    }
    return isLt2M;
    },
    handleSizeChange(val) {
      this.formLabelAlign.pageSize = val
      this.formLabelAlign.index = 1
      this.showAll()
    },
    handleCurrentChange(val) {
      this.formLabelAlign.index = val
      this.showAll()
    },
  }
}
</script>

<style  scoped>
.table{width: 1400px; margin: auto; margin-top: 50px; }

</style>
