<template>
  <div class="main">
    <div class="header">
      <div class="left">
        <img src="../assets/headerlogo.png"/>
      </div>
      <div class="right">
        <p class="sitename">数字乡村服务团队科技特派员工作网站</p>
        <p class="engname">Digital Village Service Team Technology Commissioner Work Website</p>
      </div>
    </div>

    <div class="navigator">
      <div class="menuDiv">
        <ul class="title">
        <li><router-link :to="{path:'/home/index'}">中心首页</router-link></li>
        <li><router-link :to="{path:'/home/workSite'}">工作站点</router-link></li>
        <li class="menu"><router-link :to="{path:'/home/systemDisplay'}">科技成果</router-link>
          <ul class="secondary">
            <li><router-link :to="{path:'/home/systemDisplay'}">系统展示</router-link></li>
            <li><router-link :to="{path:'/home/inventionPatent'}">专利</router-link></li>
            <li><router-link :to="{path:'/home/techInnovaAchieve'}">科技创新及成果</router-link></li>
          </ul>
        </li>
        <li class="menu"><router-link :to="{path:'/home/scienTechServ'}">科技服务</router-link>
          <ul class="secondary">
            <li><router-link :to="{path:'/home/serviceRecord'}">服务纪实</router-link></li>
            <li><router-link :to="{path:'/home/innovateEnterprise'}">创新创业</router-link></li>
            <li><router-link :to="{path:'/home/scienTechTrain'}">科技培训</router-link></li>
          </ul>
        </li>
        <li class="menuSpecial"><router-link :to="{path:'/home/workPlan'}">工作进展与计划</router-link>
          <ul class="secondary">
            <li><router-link :to="{path:'/home/workPlan'}">工作计划</router-link></li>
            <li><router-link :to="{path:'/home/workSummary'}">工作总结</router-link></li>
          </ul>
        </li>
        <li class="menu"><router-link :to="{path:'/home/contactUs'}">联系我们</router-link></li>
        <li class="menu"><router-link :to="{path: '/home/userLogin'}" class="el-icon-user-solid"></router-link></li>
      </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  }
}
</script>

<style  scoped>
*{margin: 0; padding: 0; }
.header{width:100%;height: 140px;margin: 0px; padding: 0px;}
.header img{width: 80px; height: 80px;float: left; padding-left: 300px; padding-top: 30px;}
.left{float: left;}
.right{float: left; padding-left: 20px;padding-top: 40px;}
.sitename{font-size: 24px; font-weight: bolder; padding-left: 0px; text-align: left; }
.engname{margin-top: 0px; padding-top: 10px;}
.navigator{height: 50px; background-color: #70a54d; }
.menuDiv{margin: 0 auto; width: 1540px; margin: auto; padding-left: 150px;}
.title li{list-style: none; width: 190px;  display: inline-block; position: relative; font-weight: bolder; font-size: 20px; text-align: center; color: white; line-height: 50px;text-align: left; padding-left: 10px;}
.title li:hover{color:#254b0c ;}
.secondary{background-color: rgba(138, 173, 128,0.9); position: absolute; top: 50px; display: none; width: 180px; border-radius: 0 0 5px 5px; }
.secondary>li{display: block; width: 140px;}
.secondary>li:hover {color:rgb(49, 85, 39);}
.menu:hover .secondary{display: block; z-index: 10;}
a{text-decoration: none; color: white;}
a:hover{color:#254b0c ;}
.last{padding-left: 30px;}
.menuSpecial{margin-right: 50px;}
</style>
