<template>
  <div class="main">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/backIndex/bSystemDisplay' }">科技成果</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/backIndex/bSystemDisplay' }">系统展示</el-breadcrumb-item>
    </el-breadcrumb>

    
    <div class="table">
      <el-button type="primary" style="float:right" size="small" @click="openAddDialog()">新增</el-button>
      <el-table
    :data="tableData"
    stripe
    style="width: 100%">
    <el-table-column
      prop="systemName"
      label="系统名称" align="center"
      width="250">
    </el-table-column>
    <el-table-column
      prop="systemPicture"
      label="系统图片" align="center"
      width="200">
      <template slot-scope="scope">
        <el-button @click="showImg(scope.row.systemPicture)"  type="success" size="mini">查看</el-button>
      </template>
    </el-table-column>
    <el-table-column
      prop="systemIntroduce"
      label="系统主要功能介绍" align="center"
      width="550">
    </el-table-column>
    <el-table-column
      prop="systemUrl"
      label="系统url"
      width="180">
    </el-table-column>
    <!-- <el-table-column
      prop="systemFlag"
      label="网页端/移动端" align="center"
      width="130">
    </el-table-column> -->
    <el-table-column
      prop="option"
      label="操作" align="center"
      >
      <template slot-scope="scope">
        <el-button  type="primary" size="mini" @click="openEditDialog(scope.row)">编辑</el-button>
        <el-button  type="warning" size="mini" @click="deleteSyst(scope.row)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
    </div>

    <div class="Pagination">
      <el-pagination 
        background 
        layout="total,sizes,prev, pager, next,jumper"  
        :current-page="formLabelAlign.index"
        :page-sizes="[10, 20, 30, 40,50,60]"
        :page-size="formLabelAlign.pageSize" 
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange" 
        :total="total"></el-pagination>
    </div>

<!-- 新增对话框 -->
    <el-dialog title="新增" :visible.sync="addFormVisible " >
      <el-form :model="addForm" ref="addForm">
          <el-form-item label="系统名称" :label-width="formLabelWidth" prop="systemName">
              <el-input v-model="addForm.systemName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="系统功能介绍" :label-width="formLabelWidth" prop="systemIntroduce">
              <el-input v-model="addForm.systemIntroduce" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="系统url" :label-width="formLabelWidth" prop="systemUrl">
              <el-input v-model="addForm.systemUrl" autocomplete="off"></el-input>
          </el-form-item>
          <!-- <el-form-item label="网页端/移动端" :label-width="formLabelWidth" align="left" prop="systemFlag">
              <el-select v-model="addForm.systemFlag" placeholder="请选择">
                  <el-option label="网页端" value="1"></el-option>
                  <el-option label="移动端" value="0"></el-option>
              </el-select>
          </el-form-item> -->
          <el-form-item label="系统图片" prop="systemPicture" style="margin-left:50px;" align="left">
              <el-upload
                  action=""
                  ref="uploadImg"
                  list-type="picture-card"
                  name="files"
                  :headers = "headers"
                  :limit="1"
                  :auto-upload = "false"
                  :file-list="fileList"
                  :on-success="
                      (res, file) =>
                      handleAvatarSuccess(res, file, 'addForm', 'systemPicture')
                  "
                  :on-change="addImg"
                  :on-preview="
                      (file) =>
                      handlePictureCardPreview(file)
                  "
                  :on-remove="handleRemove"
                  >
                      <i class="el-icon-plus"></i>
                  </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                  <img width="100%" :src="addForm.systemPicture" alt="" />
              </el-dialog>
          </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
          <el-button @click="addCarcer()">取 消</el-button>
          <el-button type="primary" @click="handleSubmit('addForm')">确 定</el-button>
      </div>
  </el-dialog>
<!-- 编辑 -->
  <el-dialog title="编辑" :visible.sync="editFormVisible">
    <el-form :model="editForm" ref="editForm">
        <el-form-item label="系统名称" :label-width="formLabelWidth" prop="systemName">
              <el-input v-model="editForm.systemName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="系统功能介绍" :label-width="formLabelWidth" prop="systemIntroduce">
              <el-input v-model="editForm.systemIntroduce" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="系统url" :label-width="formLabelWidth" prop="systemUrl">
              <el-input v-model="editForm.systemUrl" autocomplete="off"></el-input>
          </el-form-item>
          <!-- <el-form-item label="网页端/移动端" :label-width="formLabelWidth" align="left" prop="systemFlag">
              <el-select v-model="editForm.systemFlag" placeholder="请选择">
                  <el-option label="网页端" value="1"></el-option>
                  <el-option label="移动端" value="0"></el-option>
              </el-select>
          </el-form-item> -->
        <el-form-item label="商品图片" prop="systemPicture" style="margin-left:50px;" align="left" >
            <el-upload
                action="''"
                list-type="picture-card"
                name="files"
                ref="uploadImg"
                :file-list="fileList"
                :limit="1"
                :headers = "headers"
                :auto-upload="false"
                :on-success="
                    (res, file) =>
                    edithandleAvatarSuccess(res, file, 'editForm', 'systemPicture')
                "
                :on-change="editImg"
                :on-preview="
                    (file) =>
                    handlePictureCardPreview(file)
                "
                :on-remove="handleRemoveEdit"
                >
                    <!-- <i class="el-icon-plus"></i> -->
                    <img width="100%" class="el-icon-plus" :src="editForm.systemPicture" alt=""  style="width:100%;height:100%"/>
                </el-upload>
            <!-- <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="editForm.systemPicture" alt="" />
            </el-dialog> -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
          <el-button @click="editCarcer()">取 消</el-button>
          <el-button type="primary" @click="handleSubmit('editForm')">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog min-width="200px" title="系统图片" :visible.sync="dialogLookVisible" center>
      <div align="center">
          <img :src="pictureUrl" width="80%" style="margin: auto; height: 600px"/>
      </div>
      <p style="text-align: center; padding-top: 10px; color: red" v-show="lookPictureFlag">暂无图片</p>
    </el-dialog>
    
  </div>
</template>

<script>
import { request } from "@/network/request"
import {addressData} from "../../assets/addressData.js"
import { MessageBox } from 'element-ui'
export default {
  inject:['reload'],
  data () {
    return {
      dialogVisible: false,
      addFormVisible: false,
      editFormVisible: false,
      dialogLookVisible: false,
      lookPictureFlag: false,
      formLabelWidth:'120px',
      pictureUrl: "",
      fileList: [],
      addForm: {
        systemName: '',
        systemPicture: '',
        systemIntroduce: '',
        systemUrl: '',
        // systemFlag: '',
      },
      editForm: {
        systemName: '',
        systemPicture: '',
        systemIntroduce: '',
        systemUrl: '',
        // systemFlag: '',
      },
      tableData: [],
      formLabelAlign: {
        index:1,
        pageSize:10
      },
      total: 0,
      headers: {
         Authorization: sessionStorage.getItem('token')
      },
      editId:'',   //为了编辑的时候传参以记录每条的id
      files: {},
    }
  },
  
  mounted(){
    this.showAll();
  },
  
  methods:{
    addCarcer(){
      this.addFormVisible = false;
      request({
        url:'/kjtpy/img/deletePicture',
        method: 'post',   
        data: [this.addForm.systemPicture]
      }).then(res => {
        if(res.data.code == 200){
          console.log("新增-删除成功")
        }
      })
    },
    editCarcer(){ 
       this.editFormVisible  = false;
      this.reload()
    },

    openAddDialog() {
        this.addForm = {};
        this.addFormVisible = true;
        this.$nextTick(() => {
            this.$refs.addForm.clearValidate();
        });
    },
    //打开编辑对话框
    openEditDialog(val) {
      this.editId=val.id
      //let data = Object.assign({}, val);
      this.editForm.systemPicture=val.systemPicture
      this.editForm.systemName=val.systemName
      this.editForm.systemIntroduce=val.systemIntroduce
      this.editForm.systemUrl=val.systemUrl
      // console.log(data);
      // this.editForm = data;
      this.editFormVisible = true;
      this.$nextTick(() => {
          this.$refs.editForm.clearValidate();
      });
    },
    deleteSyst(row){
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示",{
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() =>{
        this.delete(row.id)
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        })
      })
    },
    showAll(){
      request({
        url: '/kjtpy/ShowSystem/getAllSystem',
        method: 'post',
        data: {
          index:this.formLabelAlign.index,
          pageSize: this.formLabelAlign.pageSize
        }
      }).then(res => {
        console.log(res)
        if(res.data.code == 200){
          this.tableData = [],
          this.tableData = res.data.content.data,
          this.total=res.data.content.total
        }
      }).catch(err => {

      })
    },
    // 查看图片
    showImg(val){
      this.dialogLookVisible = true;
      if(val != "" && val != undefined) {
        this.pictureUrl = val;
        this.lookPictureFlag = false;
      } else {
        this.pictureUrl = "";
        this.lookPictureFlag = true;
      }
    },
    // 图片上传成功函数
    handleAvatarSuccess(res, file, form, type) {
    if (res.code == "403") {
        this.$router.push("/");
    } else {
        this.addForm.systemPicture = res.content[0];
        console.log("赋值成功");
        console.log(res.content[0]);
    }
    },
    edithandleAvatarSuccess(res, file, form, type) {
    if (res.code == "403") {
        this.$router.push("/");
    } else {
        this.editForm.systemPicture = res.content[0];
        console.log("赋值成功");
        console.log(res.content[0]);
    }
    },
    // 图片预览函数
    handlePictureCardPreview(file) {
    this[form][type] = file.url;
    this.dialog.dialogVisible = true;
    },
    // 图片移除操作
    handleRemove(file, fileList) {
    this.uploadDisabled = false;
    this.addForm.systemPicture=""
    },
    handleRemoveEdit(file, fileList){
      // this.uploadDisabled = false;
      this.$refs.uploadImg.clearFiles();
      this.fileList = []
      // console.log([this.editForm.systemPicture])
       request({
        url: '/kjtpy/img/deletePicture',
        method: 'post',   
        data: [this.editForm.systemPicture]
      }).then(res => {
            console.log(res)
        if(res.data.code == 200){
          this.editForm.systemPicture = ""
          console.log(this.editForm)
          // console.log("删除成功")
          // this.confirmEdit()
          let data = Object.assign({},this.editForm);
          request({
            url: '/kjtpy/ShowSystem/updateSystem',
            method: 'post',
            data:data
          }).then(res => {
            console.log(data)
            if(res.data.code == 200){
              this.showAll()
            }
          }).catch(err => {
            MessageBox("更改错误,请重试")
          })
        }
      })
    },
    // 图片上传前
    beforeAvatarUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 0.5;
    if (!isLt2M) {
      this.$message.error('上传图片大小不能超过 512KB!');
    }
    return isLt2M;
    },
    addImg(file,fileList){
      this.files = file.raw
    },
    editImg(file,fileList){
      this.files = file.raw;
      console.log(this.file)
      console.log(this.fileList)
    },
    handleSubmit(form){
      this.$refs[form].validate(valid => {
        if(valid) {
          if(form == 'addForm'){
            this.confirmAdd();
          }
          if(form == 'editForm'){
            this.confirmEdit();
          }
        }else {
          this.$message({
            type: "warning",
            message: "您填写的信息有误，请重新检查",
          });
          return false;
        }
      })
    },
    // 新增
    confirmAdd(){
      let that = this;
      // let data = Object.assign({}, this.addForm);
      if(JSON.stringify(that.files) === '{}'){
        request({
            url: '/kjtpy/ShowSystem/addSystem',
            method: 'post',
            data: {
              systemName : that.addForm.systemName,
              systemPicture : '',
              systemIntroduce : that.addForm.systemIntroduce,
              systemUrl : that.addForm.systemUrl
            },
          }).then(res => {
            if(res.data.code == 200){
              that.addFormVisible = false;
              MessageBox("添加成功");
              this.$refs.uploadImg.clearFiles()
              that.showAll();
            }
          })
      }else{
        const formData = new FormData();
        formData.append("files",that.files)
        console.log(formData)
        request({
          url: '/kjtpy/img/uploadPicture',
          method: 'post',
          headers:{"Content-Type": "multipart/form-data",},
          data: formData
        }).then(res => {
          if(res.data.code == 200){
            that.addForm.systemPicture=res.data.content
            request({
              url: '/kjtpy/ShowSystem/addSystem',
              method: 'post',
              data: {
                systemName : that.addForm.systemName,
                systemPicture : (that.addForm.systemPicture).toString(),
                systemIntroduce : that.addForm.systemIntroduce,
                systemUrl : that.addForm.systemUrl
              },
            }).then(res => {
              if(res.data.code == 200){
                that.addFormVisible = false;
                MessageBox("添加成功");
                this.$refs.uploadImg.clearFiles()
                that.showAll();
              }
            })
          }
        }) 
      }
      
    },
    // 编辑
    confirmEdit(){
      // let data = Object.assign({},this.editForm);
      let that = this;
      if(JSON.stringify(that.files) === '{}'){
        request({
            url: '/kjtpy/ShowSystem/updateSystem',
            method: 'post',
            headers: {
              Authorization: token
            },
            data:{
              id: this.editId,
              systemName: that.editForm.systemName,
              systemPicture: '',
              systemIntroduce: that.editForm.systemIntroduce,
              systemUrl: that.editForm.systemUrl,
              systemFlag:1
            }
          }).then(res => {
            if(res.data.code == 200){
              console.log(res.data)
              this.editFormVisible  = false;
              this.$refs.uploadImg.clearFiles()
              this.showAll()
            }
          }).catch(err => {
            console.log(err)
            MessageBox("更改错误,请重试")
          })
        }else {
          const formData = new FormData();
          formData.append("files",that.files)
          let token = sessionStorage.getItem("token");
          request({
            url: '/kjtpy/img/deletePicture',
            method: 'post',
            data: [this.editForm.systemPicture]
          }).then(res => {
            if(res.data.code == 200){
              this.editForm.systemPicture = ""
              request({
                url: '/kjtpy/img/uploadPicture',
                method: 'post',
                headers: {
                  "Content-Type": "multipart/form-data"
                },
                data : formData
              }).then(res => {
                if(res.data.code == 200){
                  that.editForm.systemPicture = res.data.content
                  request({
                    url: '/kjtpy/ShowSystem/updateSystem',
                    method: 'post',
                    headers: {
                      Authorization: token
                    },
                    data:{
                      id: this.editId,
                      systemName: that.editForm.systemName,
                      systemPicture: (that.editForm.systemPicture).toString(),
                      systemIntroduce: that.editForm.systemIntroduce,
                      systemUrl: that.editForm.systemUrl,
                      systemFlag:1
                    }
                  }).then(res => {
                    if(res.data.code == 200){
                      console.log(res.data)
                      this.editFormVisible  = false;
                      this.$refs.uploadImg.clearFiles()
                      this.showAll()
                    }
                  }).catch(err => {
                    console.log(err)
                    MessageBox("更改错误,请重试")
                  })
            }
          })
            }
          })
        }

    },
    // 删除
    delete(val){
      request({
        url: '/kjtpy/ShowSystem/deleteBatchSystem',
        method: 'post',
        data: {
          idList: val
        }
      }).then(() => {
        this.showAll();
      }).catch(err => {
        MessageBox("删除失败")
      })
    },
     //换页
        handleSizeChange(val) {
            this.formLabelAlign.pageSize = val
            this.formLabelAlign.index = 1
            this.showAll()
        },
        handleCurrentChange(val) {
            this.formLabelAlign.index = val
            this.showAll()
        },
  }
}
</script>

<style  scoped>
.table{width: 1450px; margin: auto; margin-top: 50px; }
.Pagination{margin-top: 50px;}
</style>
