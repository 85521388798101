<template>
  <div class="Tech">
    <div class="contents">
    <div class="tabs">
      <el-tabs :tab-position="tabPosition"  @tab-click="handleClick" v-model="activeTab">
        <el-tab-pane label="系统展示" name="SystemDisplay"></el-tab-pane>
        <el-tab-pane label="专利" name="InventionPatent"></el-tab-pane>
        <el-tab-pane label="创新科技及成果" name="TechInnovaAchieve"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="label_page">
        <div class="position">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home/systemDisplay' }">科技成果</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/home/systemDisplay' }">创新科技及成果</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="display">
          <div class="header">
            <hr class="hr1"/>
              <div class="inner">
                <p class="innerChi">图片展示</p>
                <p class="innerEng">Picture Exhibition</p>
              </div>
            <hr class="hr2"/>
          </div>
          <div class="picture">
            <el-carousel @change="carouselChange" :interval="5000">
              <el-carousel-item v-for="item in total" :key="item" >
                  <div class="picture_display" v-for="data in tableData" :key="data.id">
                    <el-image :src="data.achievementsPicture"></el-image>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="header">
            <hr class="hr1"/>
              <div class="inner">
                <p class="innerChi">视频展示</p>
                <p class="innerEng">Video Exhibition</p>
              </div>
            <hr class="hr2"/>
          </div>
          <div class="video" v-for="(item,index) in videoList" :key="item.videoName">
            <div class="video_text">
              <h2>{{item.title}}</h2>
              <p>{{item.describe}}</p>
            </div>
            <div class="video_display">
                <video-player class="video-player vjs-custom-skin"
                  ref="videoPlayer"
                  :playsinline="true"
                  :options="playerOptions[index]"
                  @play="onPlayerPlay($event,index)"
                ></video-player>
            </div>
          </div>
        </div>
      </div>
      </div>
  </div>
</template>

<script>
import { request } from "@/network/request";
export default {
  name:'TechInnovaAchieve',
  data () {
    return {
      tabPosition: 'left',
      tableData:[],
      activeTab:'TechInnovaAchieve',
      total:0,
      pageSize:4,
      videoList:[
        {title:"荆楚农业科技助力乡村振兴", describe:"热烈祝贺湖北省法人科技特派员单位中南民族大学的帖军教授受邀参与荆楚农业科技助力乡村振兴直播活动。帖教授在本次直播活动中给我们详细介绍了智慧农业在乡村振兴中的应用于实践，并向我们展示了中南民族大学法人科技特派员团队取得的优异成果！让我们祝贺帖教授团队的成功，感谢他们为此付出的汗水!", videoName: "video1" },
        {title:"南京923全球农产品直播电商节访谈",describe:"在南京923全球农产品直播电商节中南民族大学计算机科学学院副院长帖军就科技助推乡村振兴接受了主持的访谈。在访谈中介绍了研发团队以及团队目前的研究领域。并且展示了带来应用于农业实践的机器人。", videoName: "video2" },
        {title:"助力合作企业打造自身品牌形象",describe:"本团队与绿林茶籽油有限公司合作，将智慧农业的核心思想和主要技术引入生产企业，指导企业开展智慧农业信息化建设，提升企业智能化办公和管理水平，第一步则是助力企业完成宣传视频，为企业打造属于自己的品牌形象。", videoName: "video3" },
      ],
      playerOptions:[],
    }
  },
  mounted:function(){
    this.getAllAchievement(1,this.pageSize);
    this.getMovieList();
  },
  methods:{
    carouselChange: function(key1, key2) {
      console.log(key1 + ",,,*," + key2);
      this.getAllAchievement(key1+1,this.pageSize)
    },
     handleClick(tab,event){
            if(tab.label == '系统展示'){
                this.$router.push({path:'/home/systemDisplay'})
            }
            else if(tab.label == '专利'){
                 this.$router.push({path:'/home/inventionPatent'})
            }
            else{
                this.$router.push({path:'/home/techInnovaAchieve'})
            }
        },
      getAllAchievement(index,pageSize){
        let that = this;
      request({
        url: "/kjtpy/achievement/getAllAchievement",
        method: "post",
        data: {
          index:index,
          pageSize:pageSize
        }
      })
      .then((res) => {
        console.log(res)
        let data = res.data;
        if(data.code === 200){
          that.tableData = [];
          that.tableData = JSON.parse(JSON.stringify(res.data.content.data)); //深拷贝
          that.total = Math.ceil(res.data.content.total/4)
          console.log(that.total)
        }else{
          this.$message({
            message: data.content,
            type: 'warning',
            showClose: true
          });
        }
        
      })
      .catch((error) => {
        console.log(error);
      });
      },
      getMovieList() {
      // 这里正常来说应该是从后台获取的数据，以下操作都是在成功的回调函数里
      //   for (var i = 0; i < this.videolist.length; i++) {
      for (var i = 0; i < this.videoList.length; i++) {
        let arrs = {
          playbackRates: [1.0, 2.0, 3.0], //播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          // preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: "zh-CN",
          aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [
            {
              type: "video/mp4",
              src: require(`../../assets/${this.videoList[i].videoName}.mp4`) //url地址
            }
          ],
          poster: "", //封面地址
          notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true //全屏按钮
          }
        };
        this.playerOptions.push(arrs);
      }
    },
    onPlayerPlay(player, index) {
       var that = this.$refs.videoPlayer;
       for (let i = 0; i < that.length; i++) {
          if(i != index)
          that[i].player.pause()
       }
    },
        //换页
    // handleCurrentChange(val) {
    //   this.formLabelAlign.index = val
    //   this.getAllAchievement(this.formLabelAlign.index,this.formLabelAlign.pageSize)
    // },
  }
}
</script>

<style  scoped lang="less">
  .Tech{width: 1440px;margin: 0 auto;}
  .contents{display: inline-block;}
  .header{padding-bottom: 20px;padding-top: 20px;}
  .tabs{ padding-top: 40px;height: 300px;width: 200px;float: left;}
  /deep/ .el-tabs__item{height: 50px;font-size: 16px;font-weight: 400;color: #172F31;}
  /deep/ .el-tabs__item:hover{color: #BFD9DA;}
  /deep/ .el-tabs__item.is-active{color: #70A54D;}
  /deep/ .el-tabs__active-bar{background: #70A54D;height: 50px;}
  .label_page{padding-top: 40px;width: 1240px;float: left;}
  .display{padding-top: 20px;display: inline-block;}
  .inner{ display: inline-block; width: 350px; }
  .innerChi{font-size: 24px; font-weight: bolder; color: rgb(136, 128, 14); }
  .innerEng{font-size: 16px; font-weight: normal;}
  .hr1{ display: inline; width: 380px; float: left; margin-left: 50px;margin-top: 60px;background-color: green;height:3px;border:none;}
  .hr2{ display: inline;width: 380px;float:right; margin-right: 50px;margin-top: 60px;background-color: green;height:3px;border:none;/*filter: blur(1px);*/}
  .video_display{width: 900px; height: 450px; margin: 0 auto; object-fit:fill;margin-top: 20px;padding-bottom: 30px;margin-bottom: 20px;}
  .video_text{width: 1200px;margin: 0 auto;padding-bottom: 20px;padding-top: 20px;}
  .video_text p{text-align: left;text-indent:2em;padding-left: 10px;padding-right: 10px;line-height:150%;font-size: 20px;}
  .picture:after{content: "";height: 0;clear: both;overflow: hidden;display: block;visibility: hidden;}
  .picture_display{width:48%;border-radius: 15px;max-width: 600px;padding: 0 10px 0 10px;overflow: hidden;float: left;margin-top: 15px;}
  /deep/ .el-carousel{height: 800px;}
  /deep/ .el-carousel__button{background-color: #95B58C;}
  /deep/ .el-carousel__container{height: 100%;}
  /deep/ .el-image{width: 100%;height: 100%;border-radius: 15px;object-fit: cover;-webkit-transition: .5s all linear;transition:.5s all linear ;}
  /deep/ .el-image:hover{transform: scale3d(1.1,1.1,1.1);-webkit-transform: scale3d(1.1,1.1,1.1);}
</style>