<template>
  <el-card>
    <div class="worksite">工作站点</div>
    <el-button type="primary" class="add" size="small" @click="add()"
      >添加</el-button
    >
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="companyName" label="公司名称" width="400">
      </el-table-column>
      <el-table-column prop="companyIntroduce" label="公司介绍" width="400">
      </el-table-column>
      <el-table-column
        label="公司图片"
        min-width="100"
        align="center"
        prop="companyPicture"
      >
        <template slot-scope="scope">
          <el-button @click="showImg(scope.row)" size="mini" type="warning"
            >查看</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="cooperationAchievements"
        label="公司成果"
        width="400"
      >
      </el-table-column>
      <el-table-column
        label="操作"
        min-width="100"
        align="center"
        prop="operate"
      >
        <template slot-scope="scope">
          <el-button
            @click="edit(scope.row)"
            size="mini"
            type="primary"
            :disabled="scope.row.isEdit == '0'"
            >编辑</el-button
          >
          <el-button @click="deleterow(scope.row)" size="mini" type="warning"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="Pagination">
      <el-pagination
        background
        layout="total,sizes,prev, pager, next,jumper"
        :current-page="formLabelAlign.index"
        :page-sizes="[5, 10, 15, 20]"
        :page-size="formLabelAlign.pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :total="total"
      ></el-pagination>
    </div>

    <!-- 添加弹框 -->
    <el-dialog
      title="添加"
      :visible.sync="addFormVisible"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-form :model="addForm" ref="addForm">
        <el-form-item label="公司名称" :label-width="formLabelWidth">
          <el-input v-model="addForm.companyName" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item
          label="公司介绍"
          :label-width="formLabelWidth"
          align="left"
        >
          <el-input
            v-model="addForm.companyIntroduce"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="公司成果"
          :label-width="formLabelWidth"
          align="left"
        >
          <el-input
            v-model="addForm.cooperationAchievements"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="公司图片"
          prop="watch"
          style="margin-left: 50px"
          align="left"
        >
          <el-upload
            action=""
            :file-list="this.addForm.companyPicture"
            :headers="headers"
            list-type="picture"
            :on-exceed="handleExceed"
            :limit="1"
            :auto-upload="false"
            :on-remove="handleRemove"
            :on-change="addImg"
          >
            <el-button slot="trigger" type="primary">上传公司照片</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="back()">取 消</el-button>
        <el-button type="primary" @click="submit()">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 编辑弹框 -->
    <el-dialog
      title="编辑"
      :visible.sync="editFormVisible"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-form :model="editForm" ref="editForm">
        <el-form-item label="公司名称" :label-width="formLabelWidth">
          <el-input
            v-model="editForm.companyName"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="公司介绍"
          :label-width="formLabelWidth"
          align="left"
        >
          <el-input
            v-model="editForm.companyIntroduce"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="公司成果"
          :label-width="formLabelWidth"
          align="left"
        >
          <el-input
            v-model="editForm.cooperationAchievements"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item style="margin-left: 50px" align="left" label="公司名称：">
          <el-upload
            action=""
            :file-list="this.editForm.companyPicture"
            :headers="headers"
            list-type="picture"
            :on-exceed="handleExceed"
            :limit="1"
            :auto-upload="false"
            :on-remove="handleRemoveEdit"
            :on-change="addImgEdit"
          >
            <el-button slot="trigger" type="primary">上传公司照片</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="back()">取 消</el-button>
        <el-button type="primary" @click="editsubmit()">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 公司图片 -->
    <el-dialog title="公司图片" :visible.sync="picVisible">
      <div>
        <img :src="pictureUrl" width="80%" />
      </div>
      <p v-show="noPicture">暂无图片</p>
    </el-dialog>
  </el-card>
</template>

<script>
import { request } from "@/network/request";
export default {
  data() {
    return {
      headers: {
        Authorization: sessionStorage.getItem("token"),
      },
      tableData: [],
      total: 0,
      formLabelAlign: {
        index: 1,
        pageSize: 1,
      },
      formLabelWidth: "120px",
      addFormVisible: false,
      addForm: {
        companyName: "",
        companyIntroduce: "",
        cooperationAchievements: "",
        companyPartner: "教育部",
        companyPicture: [],
        companyLongitude: 0,
        companyLatitude: 0,
      },
      editFormVisible: false,
      editForm: {
        id: "",
        companyName: "",
        companyIntroduce: "",
        cooperationAchievements: "",
        companyPartner: "教育部",
        companyPicture: [],
        companyLongitude: 0,
        companyLatitude: 0,
      },
      Form: {
        id: "",
        companyName: "",
        companyIntroduce: "",
        cooperationAchievements: "",
        companyPartner: "教育部",
        companyPicture: "",
        companyLongitude: 0,
        companyLatitude: 0,
      },
      picVisible: false,
      noPicture: false,
      pictureUrl: "",
    };
  },
  mounted() {
    this.showAll();
  },
  methods: {
    addImg(file, fileList) {
      this.addForm.companyPicture = fileList;
    },
    addImgEdit(file, fileList) {
      this.editForm.companyPicture = fileList;
    },
    //显示所有数据
    showAll() {
      request({
        url: "/kjtpy/WorkStation/getAllWorkStation",
        method: "post",
        data: [],
      }).then((res) => {
        if (res.data.code == 200) {
          this.tableData = res.data.content;
          this.total = res.data.content.length;
        }
      });
    },
    add() {
      this.addForm = {};
      this.addFormVisible = true;
      this.$nextTick(() => {
        this.$refs.addForm.clearValidate();
      });
    },
    submit() {
      if (this.addForm.companyPicture == undefined) {
        request({
          url: "/kjtpy/WorkStation/addWorkStation",
          method: "post",
          headers: { Authorization: sessionStorage.getItem("token") },
          data: {
            companyName: this.addForm.companyName,
            companyIntroduce: this.addForm.companyIntroduce,
            companyPartner: this.addForm.companyPartner,
            cooperationAchievements: this.addForm.cooperationAchievements,
            companyPicture: "",
            companyLongitude: "",
            companyLatitude: "",
          },
        }).then((res) => {
          if (res.data.code == 200) {
            this.showAll();
            this.addFormVisible = false;
            this.$nextTick(() => {
              this.$refs.addForm.clearValidate();
              this.$message({
                type: "success",
                message: "添加成功!",
              });
            });
          }
        });
        this.addFormVisible = false;
      }
      if (this.addForm.companyPicture[0] != null) {
        var formDatas = new FormData();
        formDatas.append("files", this.addForm.companyPicture[0].raw);
        request({
          url: "/kjtpy/img/uploadPicture",
          method: "post",
          data: formDatas,
        })
          .then((res) => {
            this.addForm.companyPicture[0].url = res.data.content[0];
            console.log("成功上传！");

            request({
              url: "/kjtpy/WorkStation/addWorkStation",
              method: "post",
              headers: { Authorization: sessionStorage.getItem("token") },
              data: {
                companyName: this.addForm.companyName,
                companyIntroduce: this.addForm.companyIntroduce,
                companyPartner: this.addForm.companyPartner,
                cooperationAchievements: this.addForm.cooperationAchievements,
                companyPicture: this.addForm.companyPicture[0].url,
                companyLongitude: "",
                companyLatitude: "",
              },
            }).then((res) => {
              if (res.data.code == 200) {
                this.showAll();
                this.addFormVisible = false;
                this.$nextTick(() => {
                  this.$refs.addForm.clearValidate();
                  this.$message({
                    type: "success",
                    message: "添加成功!",
                  });
                });
              }
            });
          })
          .catch((err) => {});
      }
    },

    // 查看公司图片
    showImg(val) {
      this.picVisible = true;
      if (val != "" && val != undefined) {
        this.pictureUrl = val.companyPicture;
        this.noPicture = false;
      } else {
        this.noPicture = true;
      }
    },

    // 编辑
    edit(val) {
      this.editFormVisible = true;
      this.editForm.companyName = val.companyName;
      this.editForm.id = val.id;
      this.editForm.companyIntroduce = val.companyIntroduce;
      this.editForm.cooperationAchievements = val.cooperationAchievements;
      if (val.companyPicture) {
        let temp = {
          name: "",
          url: val.companyPicture,
        };
        this.editForm.companyPicture.push(temp);
      }

      let data = Object.assign({}, this.editForm);
      this.Form = data;
    },
    handleRemove() {
      this.addForm.companyPicture = [];
    },
    handleRemoveEdit() {
      this.Form.companyPicture = this.editForm.companyPicture;
      this.editForm.companyPicture = [];
    },
    editsubmit() {
      if (this.editForm.companyPicture[0] == this.Form.companyPicture[0]) {
        request({
          url: "/kjtpy/WorkStation/updateWorkStation",
          method: "post",
          data: {
            id: this.editForm.id,
            companyName: this.editForm.companyName,
            companyIntroduce: this.editForm.companyIntroduce,
            companyPartner: this.editForm.companyPartner,
            cooperationAchievements: this.editForm.cooperationAchievements,
            companyPicture: "",
            companyLongitude: this.editForm.companyLongitude,
            companyLatitude: this.editForm.companyLatitude,
          },
        }).then((res) => {
          if (res.data.code == 200) {
            this.showAll();
            this.editFormVisible = false;
            window.location.reload();
            this.$nextTick(() => {
              this.$refs.editForm.clearValidate();
              this.$message({
                type: "success",
                message: "编辑成功!",
              });
            });
          }
        });
      }
      if (this.editForm.companyPicture[0] != undefined) {
        const formData = new FormData();
        formData.append("files", this.editForm.companyPicture[0].raw);
        let token = sessionStorage.getItem("token");
            this.editForm.systemPicture = "";
            request({
              url: "/kjtpy/img/uploadPicture",
              method: "post",
              headers: {
                "Content-Type": "multipart/form-data",
              },
              data: formData,
            }).then((res) => {
              if (res.data.code == 200) {
                this.editForm.companyPicture[0].url = res.data.content[0];
                console.log(this.editForm.companyPicture[0].url);
                request({
                  url: "/kjtpy/WorkStation/updateWorkStation",
                  method: "post",
                  headers: {
                    Authorization: token,
                  },
                  data: {
                    id: this.editForm.id,
                    companyName: this.editForm.companyName,
                    companyIntroduce: this.editForm.companyIntroduce,
                    companyPartner: this.editForm.companyPartner,
                    cooperationAchievements:
                      this.editForm.cooperationAchievements,
                    companyPicture: this.editForm.companyPicture[0].url,
                    companyLongitude: this.editForm.companyLongitude,
                    companyLatitude: this.editForm.companyLatitude,
                  },
                })
                  .then((res) => {
                    if (res.data.code == 200) {
                      if (
                        this.Form.companyPicture[0] != undefined &&
                        this.editForm.companyPicture[0] ==
                          this.Form.companyPicture[0]
                      ) {
                        request({
                          url: "/kjtpy/img/deletePicture",
                          method: "post",
                          data: [this.Form.companyPicture[0].url],
                        })
                          .then((res) => {
                            console.log("成功删除！");
                          })
                          .catch((err) => {});
                      }
                      console.log(res.data);
                      this.editFormVisible = false;
                      this.showAll();
                      window.location.reload();
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    alert("更改错误,请重试");
                  });
              }
            });
          
      
      }

      if (this.editForm.companyPicture[0] == undefined) {
        request({
          url: "/kjtpy/WorkStation/updateWorkStation",
          method: "post",
          
          data: {
            id: this.editForm.id,
            companyName: this.editForm.companyName,
            companyIntroduce: this.editForm.companyIntroduce,
            companyPartner: this.editForm.companyPartner,
            cooperationAchievements: this.editForm.cooperationAchievements,
            companyPicture: "",
            companyLongitude: this.editForm.companyLongitude,
            companyLatitude: this.editForm.companyLatitude,
          },
        })
          .then((res) => {
            if (res.data.code == 200) {
              request({
                url: "/kjtpy/img/deletePicture",
                method: "post",
                data: [this.Form.companyPicture[0].url],
              })
                .then((res) => {
                  console.log("成功删除！");
                })
                .catch((err) => {});

              console.log(res.data);
              this.editFormVisible = false;
              this.showAll();
              window.location.reload();
            }
          })
          .catch((err) => {
            console.log(err);
            alert("更改错误,请重试");
          });
      }
    },

    back() {
      this.editForm.companyPicture = this.Form.companyPicture;
      window.location.reload();
    },
    // 删除一行
    deleterow(val) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          request({
            url: "/kjtpy/WorkStation/deleteWorkStation",
            method: "post",
            data: {
              id: val.id,
            },
          })
            .then((res) => {
              if (res.data.code == 200) {
                this.showAll();
              }
            })
            .catch(() => {});
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1个文件`);
    },
    //换页
    handleSizeChange(val) {
      this.formLabelAlign.pageSize = val;
      this.formLabelAlign.index = 1;
      this.showAll();
    },
    handleCurrentChange(val) {
      this.formLabelAlign.index = val;
      this.showAll();
    },
  },
};
</script>

<style  scoped>
.worksite {
  float: left;
}
.add {
  float: right;
  margin-bottom: 20px;
}
.Pagination {
  margin-top: 50px;
}
</style>
