<template>
 <div>
    
    <quill-editor
      class="editor ql-editor"
      ref="myQuillEditor"
      v-model="content"
      :options="editorOption"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @ready="onEditorReady($event)">
    </quill-editor>
    <el-upload
      class="my-input-upload"
			ref="myupload"
      action="/kjtpy/img/uploadPicture"
      name="files"
      :headers="headers"
      :show-file-list="false"
      :on-success="(res, file) => uploadSuccess(res,file)"
      :before-upload="beforeUpload"
      :on-change="uploadChangeImg"
      :on-error="uploadError"
      ></el-upload>

 </div>
      
</template>
<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'



import Quill from "quill";
import imageResize  from "quill-image-resize-module"; // 引用
import { ImageDrop } from "quill-image-drop-module";

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", imageResize); // 注册

  // 工具栏配置
const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
  ["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
  [{ header: 1 }, { header: 2 }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
  [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
  [{ script: "sub" }, { script: "super" }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
  [{ indent: "-1" }, { indent: "+1" }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
  [{ direction: "rtl" }], // 文本方向-----[{'direction': 'rtl'}]
  [{ size: ["small", false, "large", "huge"] }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
  [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
  [{ font: [] }], // 字体种类-----[{ font: [] }]
  [{ align: [] }], // 对齐方式-----[{ align: [] }]
  ["clean"], // 清除文本格式-----['clean']
  ["image", "video"] // 链接、图片、视频-----['link', 'image', 'video']
];
export default {
  props:{
		editContent: {
			// 内容
			type: String,
			default: ""
		}
	},
  components: {
    quillEditor
  },
  data () {
    return {
      headers: {
         Authorization: sessionStorage.getItem('token')
      },
      // 富文本编辑器默认内容
      content: '',
      //富文本编辑器配置
      editorOption: {
         modules: {
          // imageDrop: true,
          imageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white"
            },
          },
          //工具栏定义的
          toolbar:{
            container: toolbarOptions,
						handlers: {
							image: function(value) {
								if (value) {
									// 触发input框选择图片文件
									document.querySelector('.my-input-upload input').click();
								} else {
									this.quill.format('image', false);
								}
							}
						}
          } 
          
        },
        //主题
        theme: "snow",
        placeholder: "请输入正文"
      }
    }
  },
  methods: {
      //失去焦点事件
      onEditorBlur() {},
      //获得焦点事件
      onEditorFocus() {},
      // 准备富文本编辑器
      onEditorReady() {},
      //内容改变事件
      onEditorChange() {},
      uploadSuccess(res,file) {
		 	// res为图片服务器返回的数据
		 	// 获取富文本组件实例
      console.log(res);
		 	
		 	let quill = this.$refs.myQuillEditor.quill;
       // 如果上传成功
      if (res.code == 200) {
        // 获取光标所在位置
        let path = res.content[0]
        console.log(quill.getSelection())
        let length = quill.getSelection().index;
        // 插入图片  res.url为服务器返回的图片地址
        quill.insertEmbed(length, 'image', path);
        // 调整光标到最后
		 		quill.setSelection(length + 1); 
      } else {
           this.$message.error('图片插入失败');
      }
		 	// loading动画消失
		 	this.quillUpdateImg = false;
		 },
		uploadChangeImg(){
      console.log('changeImg')
      
      // this.$refs.myupload.submit();
		},
		onEditorChange({ editor, html, text }) {
			//内容改变事件
			console.log('---内容改变事件---');
			this.content = html;
			// console.log(html);
		},
		// 富文本图片上传前
		beforeUpload(file) {
			// 显示loading动画
			// console.log(this.ossObj)
			this.quillUpdateImg = true;
			const isLt2M = file.size / 1024 / 1024 < 2;
			if (!isLt2M) {
			    this.$message.error('上传头像图片大小不能超过 2MB!');
			}
			return isLt2M;
		},
		// 富文本图片上传失败
		uploadError() {
			// loading动画消失
			this.quillUpdateImg = false;
			this.$message.error('图片插入失败');
		},
		editSubmit(){
			this.$emit('editSubmit',this.content)
		},
  },
  watch:{
    editContent: {
		　　handler(newName, oldName) {
		  　　	this.content = newName;
		　　},
		　　immediate: true
		}
  },
  mounted() {
		this.content = this.editContent;
  },
  
}
</script>
<style lang='scss'>
.editor {
  line-height: normal !important;
  height: 500px;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "16px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
</style>
