<template>
  <div>
    <div class="position">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/backIndex/bScienServ' }">科技服务</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/backIndex/bScienServ' }">科技服务编辑</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="bu_style">
      
    </div>
    <div class="Form">
    <el-form ref="form" :model="form" label-width="80px" :label-position="labelPosition">
      <el-form-item label="新闻标题" prop="title">
        <el-input v-model="form.title" size="small"></el-input>
      </el-form-item>
       <el-form-item label="新闻类型" prop="type">
         <el-select v-model="form.type" size="small" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="新闻正文" prop="content">
        <quillEditor ref="new" :editContent="form.content" @editSubmit="getEditSubmit"></quillEditor>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getContent">提交</el-button>
      </el-form-item>
    </el-form>
    </div>
  </div>
</template>

<script>
import quillEditor from "@/components/quillEditor";
import { request } from "@/network/request";
export default {
  data () {
    return {
      labelPosition:'top',
      form: {
        title:'',
        type:'',
        content: ''
      },
      content: '',
      options: [{
          value: '1',
          label: '科技培训'
        }, {
          value: '2',
          label: '服务纪实'
        }, {
          value: '3',
          label: '创新创业'
        }],
        value:'',
    }
  },
  components:{
    'quillEditor':quillEditor
  },
  methods:{
    // 提交数据
		getEditSubmit(e){
      this.form.content = e;
      this.insertNews(this.form.title,this.form.content,this.form.type);
		},

    // 提交数据，先获取富文本内容
		getContent(){
      this.$refs['new'].editSubmit()
    },

    // 新增新闻
    insertNews(serviceNewsTitle,serviceNewsContent,serviceNewsType){
      let that = this;
      request({
        url: "/kjtpy/serviceNews/insertNews",
        method: "post",
        data: {
          serviceNewsTitle:serviceNewsTitle,
          serviceNewsContent:serviceNewsContent,
          serviceNewsType:serviceNewsType,
        }
      })
      .then((res) => {
        console.log(res)
        let data = res.data;
        if(data.code === 200){
          this.$message({
            message: "新增新闻成功！",
            type: 'success',
            showClose: true
          });
          this.$refs["form"].resetFields();
        }else{
          this.$message({
            message: data.content,
            type: 'warning',
            showClose: true
          });
        }
        
      })
      .catch((error) => {
        console.log(error);
      });
      },
  }
}
</script>

<style  lang='less' scoped>
.bu_style{text-align: right;}
.Form{text-align: left;}
</style>
