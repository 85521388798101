<template>
  <div class="main">
    <div class="header">
      <hr class="hr1"/>
      <div class="inner">
        <p class="innerChi">工作站点信息</p>
        <p class="innerEng">Information of Work Site</p>
      </div>
      <hr class="hr2"/>
    </div>
    <div class="information">
      <el-tabs :tab-position="tabPosition" style="height: 500px;" @tab-click="handleClick">
        <el-tab-pane v-for="(item,index) in companyItemList" :key="index" >
          <span slot="label" class="item">{{item.companyName}}</span>
          <div class="rectangle">
            <div class="left">
              <p><span class="title">公司名称：</span>{{item.companyName}}</p>
              <p><span class="title">公司介绍：</span>{{item.companyIntroduce}}</p>
              <p><span class="title">工作和成果介绍：</span>{{item.cooperationAchievements}}</p>
            </div>
            <div class="right">
              <el-image :src="item.companyPicture" class="compImg"
                
              ></el-image>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { MessageBox } from "element-ui";
import { request } from "@/network/request"
export default {
  data () {
    return {
      tabPosition: 'left',
      companyItemList: [],
      item: {
        companyName: '',
        companyIntroduce: '',
        cooperationAchievements: '',
        companyPicture: ''
      },
      }
    },
    mounted () {
      this.show()
    },
    methods: {
      show(){
        request({
          url: "/kjtpy/WorkStation/getAllWorkStation",
          method: "post",
          data:{
            
          }
        }).then(res => {
          if(res.data.code == 200){
            this.companyItemList=res.data.content;
          }else if(res.data.code == 500){
            MessageBox.alert("未找到信息");
          }

        }).catch(err => {
          
        })
      },
    },  
}
</script>

<style  scoped lang="less">
.main{width: 1440px; margin: 0 auto;}
.header{margin-top: 50px;}
.inner{ display: inline-block; width: 350px; }
.innerChi{font-size: 24px; font-weight: bolder; color: rgb(136, 128, 14); }
.innerEng{font-size: 16px; font-weight: normal;}
.hr1{ display: inline; width: 480px; float: left; margin-left: 50px;margin-top: 60px;background-color: green;height:3px;border:none;}
.hr2{ display: inline;width: 480px;float:right; margin-right: 50px;margin-top: 60px;background-color: green;height:3px;border:none;/*filter: blur(1px);*/}

.information{width: 1490px; display: inline-block; margin-top: 50px;}
/deep/ .el-tabs__active-bar {background: rgb(0,128,0);}
.item{font-size: 18px;color: white;background-color: rgb(112,165,77);display:inline-block; margin-bottom: 20px; width: 330px; height: 70px; text-align: center; border-radius: 5px; line-height: 70px;}
.item:hover{ color: rgb(14,86,4);}
/deep/ .el-tabs__item{margin-bottom: 5px;height: 70px;}
/deep/ .el-tabs__nav-wrap::after{background-color: #fff;}
.rectangle{border: #0E8829 1px solid;width: 1040px;height: 450px;margin-left: 30px;background-color: white;display: inline-block; overflow: auto; border-radius: 10px; box-shadow: rgb(160, 165, 158) 0px 0px 30px ;}
.left{ width: 45%;margin: 30px;float: left;}
.left p{text-align: left; font-size: 17px;}
.right{float: left;width:45%;padding: 0.25rem;height: 300px;margin:10px;}
.compImg{width: 100%; height: 100%; margin-top: 15px;}
.title{color: rgb(13, 114, 21); font-weight: bold; }
</style>
