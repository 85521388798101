<template>
<div class="Form">
  <div class="bu_style">
    <el-button type="success" @click="goBack" >返回上一级</el-button>
  </div>
  <el-form ref="form" :model="form" label-width="80px" :label-position="labelPosition">
      <el-form-item label="新闻标题" prop="serviceNewsTitle">
        <el-input v-model="form.serviceNewsTitle" size="small"></el-input>
      </el-form-item>
       <el-form-item label="新闻类型" prop="serviceNewsType">
         <el-select v-model="form.serviceNewsType" size="small" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="新闻正文" prop="serviceNewsContent">
        <quillEditor ref="son" :editContent="form.serviceNewsContent" @editSubmit="getEditSubmit"></quillEditor>
      </el-form-item>
      <el-form-item>
        <div class="bu_style">
          <el-button type="primary" @click="getContent" >确认修改</el-button>
        </div>
      </el-form-item>
    </el-form>
</div>
    
</template>
<script>
import { request } from "@/network/request";
import quillEditor from "@/components/quillEditor";
export default {
  components: {
    'quillEditor':quillEditor
  },
  data () {
    return {
      labelPosition:'top', 
      form: [],
      content: '', 
      options: [{
          value: '1',
          label: '科技培训'
        }, {
          value: '2',
          label: '服务纪实'
        }, {
          value: '3',
          label: '创新创业'
        }],      
    }
  },
  
  methods: {
    goBack(){
      this.$router.back();
    },
    getEditSubmit(e){
      this.form.serviceNewsContent = e;
      this.onSubmit()
		},
    // 提交数据，先获取富文本内容
		getContent(){
      this.$refs['son'].editSubmit()
    },

    /*接口函数*/

    // 获取数据接口
    loadData(){
      request({
        url: '/kjtpy/serviceNews/getNewsById',
        method: 'post',
        data:{
          serviceNewsId:this.$route.params.id,
        }
      }).then(res => {
        if(res.data.code == 200){
          console.log(res)
          this.form = [];
          this.form = res.data.content; //深拷贝
          console.log(this.form.serviceNewsContent)
        }
      }).catch((error) => {
        console.log(error);
      });
    },
    // 提交数据接口
    onSubmit () {
       request({
        url: '/kjtpy/serviceNews/updateNews',
        method: 'post',
        data:{
          serviceNewsId:this.$route.params.id,
          serviceNewsTitle:this.form.serviceNewsTitle,
          serviceNewsContent:this.form.serviceNewsContent,
          serviceNewsType:this.form.serviceNewsType,
        }
      }).then(res => {
        if(res.data.code == 200){
          console.log(res)
          this.$message({
            message: res.data.content,
            type: 'success',
            showClose: true
          });
          this.$refs["form"].resetFields();
        }else{
          this.$message({
            message: res.data.content,
            type: 'warning',
            showClose: true
          });
        }
      }).catch((error) => {
        console.log(error);
      });
        }
    },
    created: function () {
    },
    mounted: function () {
      this.loadData()
    },
}
</script>
<style>
.Form{text-align: left;}
.bu_style{padding: 20px 0 20px 0;}
</style>