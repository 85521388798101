<template>
  <el-container class="tab">
      <div class="tabs">
        <el-tabs :tab-position="tabPosition"  @tab-click="handleClick" v-model="activeTab">
          <el-tab-pane label="服务纪实" name="serviceRecord"></el-tab-pane>
          <el-tab-pane label="创新创业" name="innovateEnterprise"></el-tab-pane>
          <el-tab-pane label="科技培训" name="scienTechTrain"></el-tab-pane>
        </el-tabs>
      </div>
    <div class="scienTechServ">
        <i class="el-icon-location-information" ></i>
        <div>当前位置：科技服务</div>
        <!-- <div class="line"></div> -->
      <!-- 科技培训标题 -->
        <div class="title">
          <div class="linecoach1"></div>
          <div class="coach">服务纪实</div>
          <div class="linecoach2"></div>
        </div>
        <!-- 科技培训轮播图和列表 -->
        <div class="tech">
         <div class="left">
          <el-carousel indicator-position="outside" arrow="never">
              <el-carousel-item v-for="(img,index) in imgList1" :key="index">
              <img v-bind:src="img.url" width=100% height=100%>
              </el-carousel-item>
          </el-carousel>
         </div>
        <div class="right">
          <el-button plain size="mini" @click="record">more+</el-button>
          <div class="NewsList">
            <div class="first" v-for="data in serviceData" :key="data.serviceNewsId">
              <router-link  :to="'/home/sampleNews/'+data.serviceNewsId">{{data.serviceNewsTitle}}</router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- 服务纪实标题 -->
        <div class="title">
          <div class="linecoach1"></div>
          <div class="coach">创新创业</div>
          <div class="linecoach2"></div>
        </div>
        <!-- 服务纪实轮播图和列表 -->
        <div class="tech">
         <div class="left">
          <el-carousel indicator-position="outside" arrow="never">
              <el-carousel-item v-for="(img,index) in imgList2" :key="index">
              <img v-bind:src="img.url" width=100% height=100%>
              </el-carousel-item>
          </el-carousel>
         </div>
         <div class="right">
          <el-button plain size="mini" @click="enterprise">more+</el-button>
          <div class="NewsList">
            <div class="first" v-for="data in innovateData" :key="data.serviceNewsId">
              <router-link :to="'/home/sampleNews/'+data.serviceNewsId" :title="data.serviceNewsTitle">{{data.serviceNewsTitle}}</router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- 创新创业标题 -->
        <div class="title">
          <div class="linecoach1"></div>
          <div class="coach">科技培训</div>
          <div class="linecoach2"></div>
        </div>
        <!-- 科技培训轮播图和列表 -->
        <div class="tech">
         <div class="left">
          <el-carousel indicator-position="outside" arrow="never">
              <el-carousel-item v-for="(img,index) in imgList3" :key="index">
              <img v-bind:src="img.url" width=100% height=100%>
              </el-carousel-item>
          </el-carousel>
         </div>
        <div class="right">
          <el-button plain size="mini" @click="train">more+</el-button>
          <div class="NewsList">
            <div class="first" v-for="data in techData" :key="data.serviceNewsId">
             <router-link :to="'/home/sampleNews/'+data.serviceNewsId">{{data.serviceNewsTitle}}</router-link>
            </div>
          </div>
          
        </div>
      </div>

    </div>
    <!-- <el-main class="main">
      <router-view></router-view>
    </el-main> -->
  </el-container>
</template>

<script>
import { request } from "@/network/request";
export default {
  data () {
    return {
      tabPosition: 'left',
      activeTab:'SystemDisplay',
     imgList1:[
        {url:require('../../assets/img/pic1.jpg')},
        {url:require('../../assets/img/pic5.jpg')}
      ],
      imgList2:[
        {url:require('../../assets/img/pic3.jpg')},
        {url:require('../../assets/img/pic4.jpg')}
      ],
      imgList3:[
        {url:require('../../assets/img/pic2.jpg')},
        {url:require('../../assets/img/pic6.jpg')}
      ],
      serviceData:[],
      innovateData:[],
      techData:[],
    }
  },
  mounted:function(){
    this.getAllNews()
  },
  methods:{
    getAllNews(index,pageSize){
      let that = this;
      request({
        url: "/kjtpy/serviceNews/getAllNews",
        method: "post",
        data: {
          index:index,
          pageSize:pageSize,
        }
      })
      .then((res) => {
        console.log(res)
        let data = res.data;
        if(data.code === 200){
          let x=0
          let y=0
          let z=0
         for(let i=0;i<res.data.content.size;i++){
           if(res.data.content.list[i].serviceNewsType === 1 & x<6)
           {
             this.techData.push(res.data.content.list[i])
             x=x+1
           }
           else if(res.data.content.list[i].serviceNewsType === 2 & y<6)
           {
             this.serviceData.push(res.data.content.list[i])
             y=y+1
           }
           else if(res.data.content.list[i].serviceNewsType === 3 & z<6){
             this.innovateData.push(res.data.content.list[i])
             z=z+1
           }
         }
         console.log(this.innovateData)
        }else{
          this.$message({
            message: data.content,
            type: 'warning',
            showClose: true
          });
        } 
      })
      .catch((error) => {
        console.log(error);
      });
      },
     handleClick(tab,event){
            if(tab.label == '服务纪实'){
                this.$router.push({path:'/home/serviceRecord'})
            }
            else if(tab.label == '创新创业'){
                 this.$router.push({path:'/home/innovateEnterprise'})
            }
            else{
                this.$router.push({path:'/home/scienTechTrain'})
            }
      },
    record(){
      this.$router.push({path:'/home/serviceRecord'})
    },
    enterprise(){
      this.$router.push({path:'/home/innovateEnterprise'})
    },
    train(){
      this.$router.push({path:'/home/scienTechTrain'})
    }
  }
}
</script>

<style  scoped lang='less'>
  .tab{width: 1440px;margin:0 auto;}
  .tabs{ padding-top: 40px;height: 300px;width: 200px;float: left;margin-left:3.3%;}
  /deep/ .el-tabs__item{height: 50px;font-size: 16px;font-weight: 400;color: #172F31;}
  /deep/ .el-tabs__item:hover{color: #BFD9DA;}
  /deep/ .el-tabs__item.is-active{color: #70A54D;}
  /deep/ .el-tabs__active-bar{background: #70A54D;height: 50px;}
  .scienTechServ{margin: 2%;width: 100%;}
  .scienTechServ i{float: left;width: 4%;}
  .scienTechServ div{float: left;margin-bottom: 1%;}
  .scienTechServ .line{width: 100%;height: 2px;background-color:#70A54D;}
  /* 科技培训 */
  .title{margin-top: 3%;width: 100%;}
  .title .linecoach1{float: left;margin-top: 1%;width: 40%;height: 2px;background-color:#70A54D;}
  .coach{margin-left: 6%;font-size: 22px; font-weight: bolder; color: rgb(136, 128, 14);}
  .title .linecoach2{float: right;margin-top: 1%;width: 40%;height: 2px;background-color:#70A54D;}
  /* 轮播图 */
  .left{width: 500px;float: left;margin-top: 10px;}
  .el-carousel{width: 390px;}
  /* 列表 */
  .NewsList{width: 600px;}
  .right{width: 600px;float: right;margin-left: 20px;}
  .right .el-button{float: right;}
  .first{width: 80%;text-align: left;overflow: hidden;padding-bottom: 18px;border-bottom: 1px solid #e6e6e6;font-size: 16px}
  div {margin: 0;padding: 0;border: 0;}
  a{cursor: pointer;outline: none;color: #393939;text-decoration: none;width:450px;overflow: hidden;text-overflow: ellipsis; white-space: nowrap;display: block;}
</style>
