import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VideoPlayer from 'vue-video-player'
import BaiduMap from 'vue-baidu-map'
import VueLazyload from 'vue-lazyload'
// import VueQuillEditor from 'vue-quill-editor'
// import VueJsonp from 'vue-jsonp'
import Router from 'vue-router'



// require styles
// require('quill/dist/quill.core.css')
// require('quill/dist/quill.snow.css')
// require('quill/dist/quill.bubble.css')



require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')

Vue.config.productionTip = false
Vue.use(ElementUI)
// Vue.use(VueQuillEditor)
Vue.use(VideoPlayer)
Vue.use(BaiduMap,{
  ak:'YhbSXlFYifR1w6NGSX2ONl8GTOtZgcl4'
})
Vue.use(VueLazyload);
// Vue.use(VueJsonp)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

const isLogin = () => Boolean(sessionStorage.getItem('token')); //判断是否登录
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
// 拦截路由跳转
// 如果直接使用to.meta判断也可以，就是需要给所有需要控制的路由添加requireAuth
// 而to.matched则需要给高一级的路由添加requireAuth即可，其下的所有子路由不必添加
  if(to.matched.some(item => item.meta.requireAuth)){   //.some()是es6语法，只要有一个true就是true， .every()全部为true才为true
    if(!isLogin()){
      alert('您还没有登录，请先登录');
      next({
        path: '/home/userLogin',
        replace: true
      })
    }else{
      next()
    }
  }else{
    next()
  }

  next();
})
